// Обратная связь

.feedback {

	padding: 1em;
}

.feedback-form {

	.feedback-col-6 {
		float: left;
		padding: 0 0.5em 0 0;
		width: 60%;

		&.last {
			padding: 0 0 0 0.5em;
			width: 40%;

			.feedback-block {
				margin: 0 0 1em;
			}
		}
	}

	.feedback-button-block {
		float: left;
		margin: 0;
		width: 100%;

		.form_title {
			position: static;
		}

		.form_content {
			margin: 0.5em 0 0;
		}
	}


	input {
		width: 100%;

		&.button {
			width: auto;
		}
	}

	textarea {
		resize: none;
		margin: 0 0 0.5em;
		height: 152px;
		width: 100%;
	}
}

