@charset "UTF-8";
/* Базовые: Ширина контента<br><span>($containerWidth)</span> */
/* Базовые: Подключение Google Fonts<br><span>($fontsGoogle)</span> */
/* Базовые: Толщина шрифта<br><span>($weightFonts)</span> */
/* Базовые: Название шрифта<br><span>($fontName)</span> */
/* Базовые: Размер шрифта<br><span>($fontSizeBase)</span> */
/* Базовые: Белый цвет<br><span>($white)</span> */
/* Базовые: Черный цвет<br><span>($black)</span> */
/* Базовые: Цвет ссылок<br><span>($linkColor)</span> */
/* Базовые: Цвет ссылок при наведении<br><span>($linkColor--hover)</span> */
/* Базовые: Подсветка ссылки<br><span>($webkitHighlightTapColor)</span> */
/* Базовые: Цвет текста<br><span>($textColor)</span> */
/* Базовые: Серый цвет<br><span>($grey)</span> */
/* Базовые: Цвет иконок<br><span>($iconColor)</span> */
/* Базовые: Цвет фона<br><span>($bgColor)</span> */
/* Базовые: Цвет фона после загрузки страницы<br><span>($bgLoaded)</span> */
/* Базовые: Цвет фона верхней шапки<br><span>($headerTopBgColor)</span> */
/* Базовые: Цвет фона нижней шапки<br><span>($headerBottomBgColor)</span> */
/* Базовые: Цвета ошибок<br><span>($colorError)</span> */
/* Базовые: Цвета ОК<br><span>($colorOk)</span> */
/* Базовые: Цвет фона верхней шапки на мобильных<br><span>($headerTopBgColorMobile)</span> */
/* Базовые: Высота мобильной фиксированной шапки<br><span>($mobileFixedHeaderHeight)</span> */
/* Header: Logo раположение<br><span>($logoPos)</span> */
/* Header: Logo margin<br><span>($logoMargin)</span> */
/* Header: Logo padding<br><span>($logoPadding)</span> */
/* Header: Logo ширина<br><span>($logoWidth)</span> */
/* Header: Logo img ширина<br><span>($logoWidth)</span> */
/* Header: Logo img макс ширина<br><span>($logoImgmaxWidth)</span> */
/* Header: Logo img высота<br><span>($logoImgheight)</span> */
/* Header: Logo img макс высота<br><span>($logoWidth)</span> */
/* Header: Header-Contacts раположение<br><span>($heaContFloat)</span> */
/* Header: Header-Contacts padding<br><span>($heaContPadding)</span> */
/* Header: Header-Contacts ширина<br><span>($heaContWidth)</span> */
/* Footer: Footer-Top background<br><span>($footerTopBgColor)</span> */
/* Footer: Footer-Bottom background<br><span>($footerBottomBgColor)</span> */
/* Рамки: Цвет<br><span>($borderColor)</span> */
/* Рамки: Скругление<br><span>($borderRadius)</span> */
/* Рамки: Стиль<br><span>($border)</span> */
/* Кнопки: Градиент да/нет? Если нет, используется цвет 1<br><span>($isButtonGradient)</span> */
/* Кнопки: Тень, да/нет<br><span>($isButtonShadow)</span> */
/* Кнопки: Рамка, да/нет<br><span>($isButtonBorder)</span> */
/* Кнопки: Цвет фона 1 (нижний)<br><span>($buttonBgColor1)</span> */
/* Кнопки: Цвет фона 1 (верхний)<br><span>($buttonBgColor2)</span> */
/* Кнопки: Тень<br><span>($buttonShadow)</span> */
/* Кнопки: Цвет текста<br><span>($buttonColor)</span> */
/* Кнопки: Скругления<br><span>($buttonBorderRadius)</span> */
/* Кнопки: Цвет фона 2 (нижний) при наведении<br><span>($buttonBgColor1--hover)</span> */
/* Кнопки: Цвет фона 2 (верхний) при наведении<br><span>($buttonBgColor2--hover)</span> */
/* Кнопки: Цвет текста при наведении<br><span>($buttonColor--hover)</span> */
/* Кнопки: Рамка<br><span>($buttonBorder)</span> */
/* Кнопки: Цвет рамки при наведении<br><span>($buttonBorderColor--hover)</span> */
/* Кнопки: Тень наведении<br><span>($buttonShadow--hover)</span> */
/* Кнопки: Цвет фона 1 (нижний) активный<br><span>($buttonBgColor1--active)</span> */
/* Кнопки: Цвет фона 2 (верхний) активный<br><span>($buttonBgColor2--active)</span> */
/* Кнопки: Цвет текста активный<br><span>($buttonColor--active)</span> */
/* Кнопки: Цвет рамки активный<br><span>($buttonBorderColor--active)</span> */
/* Кнопки: Тень в активном состоянии<br><span>($buttonShadow--active)</span> */
/* Инпуты: Есть ли рамка<br><span>($isInputBorder)</span> */
/* Инпуты: Есть ли тень<br><span>($isInputShadow)</span> */
/* Инпуты: Цвет текста<br><span>($inputColor)</span> */
/* Инпуты: Цвет фона<br><span>($inputBg)</span> */
/* Инпуты: Цвет рамки<br><span>($inputBorderColor)</span> */
/* Инпуты: Тень<br><span>($inputShadow)</span> */
/* Модули: Цвет фона<br><span>($moduleBg)</span> */
/* Модули: Цвет фона заголовков<br><span>($moduleHeaderBg)</span> */
/* Модули: Цвет заголовков<br><span>($moduleHeaderColor)</span> */
/* Модули: Рамка<br><span>($moduleBorder)</span> */
/* Модули: Тень сверху<br><span>($module_top__box-shadow)</span> */
/* Модули: Цвет фона заголовков в мобильной версиии<br><span>($moduleMobileHeaderBg)</span> */
/* Модули: Цвет заголовков в мобильной версиии<br><span>($moduleMobileHeaderColor)</span> */
/* Горизонтальное меню: Градиент да/нет Если нет, используется цвет 1<br><span>($isTopMenuGradient)</span> */
/* Горизонтальное меню: Тень да/нет<br><span>($isTopMenuShadow)</span> */
/* Горизонтальное меню: Рамка да/нет<br><span>($isTopMenuBorder)</span> */
/* Горизонтальное меню: Закругления да/нет<br><span>($isTopMenuBorderRounded)</span> */
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1)</span> */
/* Горизонтальное меню: Цвет фона 2 (верхний)<br><span>($topMenuBgColor2)</span> */
/* Горизонтальное меню: Рамка<br><span>($topMenuBorder)</span> */
/* Горизонтальное меню: Тень кнопок<br><span>($topMenuShadow)</span> */
/* Горизонтальное меню: Цвет текста<br><span>($topMenuColor)</span> */
/* Горизонтальное меню: Скругления<br><span>($topMenuBorderRadius)</span> */
/* Горизонтальное меню: Цвет текста при наведении<br><span>($topMenuColor--hover)</span> */
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1--selected)</span> */
/* Горизонтальное меню: Цвет фона 2 (верхний) выбранный раздел<br><span>($topMenuBgColor2--selected)</span> */
/* Товар: Цвет заголовка<br><span>($prodTitleColor)</span> */
/* Товар: Цвет текста<br><span>($prodTextColor)</span> */
/* Товар: Цвет текста следующий<br><span>($prodSecondColor)</span> */
/* Товар: Цвет текущей цены<br><span>($prodCurrentPriceColor)</span> */
/* Товар: Цвет старой цены<br><span>($prodOldPriceColor)</span> */
/* Вертикальный градиент вверх */
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400, 500, 700&subset=cyrillic");
.clearfix:before, .container:before, .header-bottom .logo:before, .header-bottom .logo a:before, .header-bottom .header-contacts:before, .header-bottom .righ_block:before, .footer-top .contacts .logo:before, .footer-top .contacts .logo a:before, .footer-top .footer-menu:before, .product .short_description:before, .product .characters-table .characters-table__row:before, .kolvo_buy_button_add:before, .product-comments-form:before, .responses_content:before, .catalog-main__wrappa.set-5 > .row:before, .catalog-main:before, .catalog-list:before, .category-list:before, .filter_block_wrapper:before, .filter_block_wrapper .filter_top:before, .filter_block_wrapper .filter_mid:before, .brand-list:before, .search_box .price-wrapper:before, .albums_list:before,
.photos_list:before, .module-inner .news-block:before, .news-list .news-block__item:before, .news-detail .news-detail__text:before,
.clearfix:after,
.container:after,
.header-bottom .logo:after,
.header-bottom .logo a:after,
.header-bottom .header-contacts:after,
.header-bottom .righ_block:after,
.footer-top .contacts .logo:after,
.footer-top .contacts .logo a:after,
.footer-top .footer-menu:after,
.product .short_description:after,
.product .characters-table .characters-table__row:after,
.kolvo_buy_button_add:after,
.product-comments-form:after,
.responses_content:after,
.catalog-main__wrappa.set-5 > .row:after,
.catalog-main:after,
.catalog-list:after,
.category-list:after,
.filter_block_wrapper:after,
.filter_block_wrapper .filter_top:after,
.filter_block_wrapper .filter_mid:after,
.brand-list:after,
.search_box .price-wrapper:after,
.albums_list:after,
.photos_list:after,
.module-inner .news-block:after,
.news-list .news-block__item:after,
.news-detail .news-detail__text:after {
  content: " ";
  display: table; }

.clearfix:after, .container:after, .header-bottom .logo:after, .header-bottom .logo a:after, .header-bottom .header-contacts:after, .header-bottom .righ_block:after, .footer-top .contacts .logo:after, .footer-top .contacts .logo a:after, .footer-top .footer-menu:after, .product .short_description:after, .product .characters-table .characters-table__row:after, .kolvo_buy_button_add:after, .product-comments-form:after, .responses_content:after, .catalog-main__wrappa.set-5 > .row:after, .catalog-main:after, .catalog-list:after, .category-list:after, .filter_block_wrapper:after, .filter_block_wrapper .filter_top:after, .filter_block_wrapper .filter_mid:after, .brand-list:after, .search_box .price-wrapper:after, .albums_list:after,
.photos_list:after, .module-inner .news-block:after, .news-list .news-block__item:after, .news-detail .news-detail__text:after {
  clear: both; }

.img-responsive, .landing-list__item-image img, .category-list .category-list__item-image, .news-block .news-block__image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 100%; }

/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Forms
   ========================================================================== */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button, .popup-block.add-to-cart .link-to-cart,
input,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button, .popup-block.add-to-cart .link-to-cart,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button, .popup-block.add-to-cart .link-to-cart,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button, .popup-block.add-to-cart .link-to-cart,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner, .popup-block.add-to-cart .link-to-cart::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring, .popup-block.add-to-cart .link-to-cart:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

.preloader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #07295e;
  animation: spin 2s linear infinite;
  z-index: 3; }
  .preloader:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: #e48a0a;
    animation: spin 1.5s linear infinite; }
  .preloader:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: rgba(253, 30, 15, 0.7);
    animation: spin 3s linear infinite; }

.aniback-block {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -2em;
  right: -2em;
  z-index: 50; }
  .aniback-block > .bottom,
  .aniback-block > .top {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 50%; }
  .aniback-block > .top {
    top: 0; }
  .aniback-block > .bottom {
    top: 50%; }

.loaded .aniback-block > .top {
  transform: translateY(-100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.5s;
  visibility: hidden; }

.loaded .aniback-block > .bottom {
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.38, 0.71), visibility 0.6s;
  visibility: hidden; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/**************************************/
/*    Миксины для медиа запросов      */
/*    Использование:                  */
/*    @include media(lg/md/.../) {    */
/*         селектор {}                */
/*    }                               */
/*                                    */
/**************************************/
/**************************************/
/*    Брейкпоинты                     */
/**************************************/
/* Первые десктопы */
/* Медиа миксины. Как использовать см. внутри */
@font-face {
  font-family: 'FontAwesome';
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0");
  src: url("/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* подгрузка шрифтов */
.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  /*background: $white - 50%;*/ }

main {
  width: 100%; }
  main > .container {
    background: #fff; }

aside {
  float: left;
  padding: 0 1em;
  width: 270px;
  overflow: hidden; }
  @media only screen and (max-width: 992px) {
    aside {
      float: none;
      width: 100%; } }

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height: calc(100vh - 99px - 49px - 233px - 48px); }
  @media only screen and (max-width: 992px) {
    .content-block {
      float: none;
      width: 100%; } }

section {
  margin: 1em 0 4em;
  padding: 0 1em; }
  section:not(#landing-list) {
    background: url(../images/footer-fon.jpg) 0 0;
    border-bottom: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }
  section#landing-list {
    padding: 0; }
  section#yamap {
    margin: 1em 0 0;
    padding: 0;
    background: none; }
    section#yamap .module-inner {
      margin: 0; }
  section .feedback {
    background: #fff; }

.mobile section,
.tablet section {
  padding: 0; }

/* Основной склелет */
div, form, table {
  /*outline: 1px solid #3a87ad !important;*/ }

input::-ms-clear {
  display: none; }

body {
  background: #fff url(../images/fon.jpg) 0 0 fixed;
  font: normal 14px/1.5 "Arial", "Helvetica", sans-serif;
  color: #000;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none; }

.loaded body {
  overflow-x: hidden;
  overflow-y: scroll;
  height: auto; }

table {
  border-collapse: collapse; }

ul {
  margin-top: 0;
  margin-bottom: 0; }

.content-inner ul {
  padding-left: 18px; }

h1 {
  color: #07295e;
  font-size: 28px; }

h2 {
  color: #07295e;
  font-size: 24px; }

h3 {
  color: #07295e;
  font-size: 18px; }

h1, h2, h3, h4, h5, h6, p {
  padding: 0 0 1em; }

a {
  color: #07295e;
  transition: color 0.3s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }

a:hover {
  color: #e48a0a;
  text-decoration: underline;
  transition: color 0.3s ease; }

.alert {
  color: red; }

.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center; }

.noscript_alert > span {
  font-weight: bold;
  color: red; }

.content-inner {
  margin: 1em 0 3em; }
  .content-inner .title {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold; }
  .content-inner .restore_pass-section {
    display: block;
    margin: 1em 0 0;
    max-width: 320px;
    padding: 1em; }
    .content-inner .restore_pass-section > .popup-block__cross {
      display: none; }
    .content-inner .restore_pass-section > .popup-block__title {
      display: none; }
    .content-inner .restore_pass-section > .restore_pass-form {
      max-width: 292px; }
      .content-inner .restore_pass-section > .restore_pass-form .form_block {
        margin: 0; }
        .content-inner .restore_pass-section > .restore_pass-form .form_block .form_title {
          position: static; }
        .content-inner .restore_pass-section > .restore_pass-form .form_block.key-title {
          margin: 0 0 0.5em; }
      .content-inner .restore_pass-section > .restore_pass-form .input-text {
        width: 100%; }

.col-bs-3 {
  float: left;
  padding: 0 1em;
  width: 25%; }

.col-bs-4 {
  float: left;
  padding: 0 1em;
  width: 33.333%; }

.col-bs-6 {
  float: left;
  padding: 0 1em;
  width: 50%; }

.col-bs-3-r {
  float: right;
  padding: 0 1em;
  width: 25%; }

.col-bs-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%; }

.col-bs-6-r {
  float: right;
  padding: 0 1em;
  width: 50%; }

@media only screen and (max-width: 768px) {
  .col-bs-3 {
    width: 50%; }
  .col-bs-4 {
    width: 50%; }
  .col-bs-6 {
    width: 100%; }
  .col-bs-3-r {
    width: 50%; }
  .col-bs-4-r {
    width: 50%; }
  .col-bs-6-r {
    width: 100%; } }

@media only screen and (max-width: 480px) {
  .col-bs-3,
  .col-bs-4,
  .col-bs-6,
  .col-bs-3-r,
  .col-bs-4-r,
  .col-bs-6-r {
    width: 100%; } }

.path {
  color: #07295e;
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  padding: .6em 0;
  margin: 1em 0 0;
  position: relative; }
  .path:before {
    background: linear-gradient(to right, #07295e 0%, #07295e 15%, rgba(255, 255, 255, 0) 70%);
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .path > a {
    font-weight: normal; }

/* Вид хлебных крошек на мобильных */
@media only screen and (max-width: 768px) {
  .mobile .path,
  .tablet .path {
    background: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
    font-size: 12px;
    line-height: 22px;
    padding: .6em 1em;
    border-radius: 5px; }
    .mobile .path:before,
    .tablet .path:before {
      display: none; } }

.content {
  position: relative; }
  .content img {
    height: auto;
    max-width: 800px;
    /*width: 100%;*/ }
    @media only screen and (max-width: 992px) {
      .content img {
        max-width: 600px; } }
    @media only screen and (max-width: 768px) {
      .content img {
        max-width: 100%; } }

button, .popup-block.add-to-cart .link-to-cart,
.button {
  background: linear-gradient(to top, #ee6300, #dfce42);
  border-radius: 5px;
  border: 1px solid #ce5500;
  border-radius: 5px;
  /* Стили кнопки по-умолчанию */
  display: inline-block;
  font-weight: 600;
  color: #002949;
  cursor: pointer;
  outline: none;
  padding: 7px 14px;
  pointer-events: all;
  text-decoration: none;
  transition: all 0.2s ease;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
  -webkit-appearance: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  /* Основные стили настроек кнопки см. /src/scss/vars */
  outline: none;
  transition: all 0.3s ease; }
  button:hover, .popup-block.add-to-cart .link-to-cart:hover,
  .button:hover {
    background: linear-gradient(to top, #ff8513, #fff064); }
  button:hover, .popup-block.add-to-cart .link-to-cart:hover,
  .button:hover {
    border-color: #f07711;
    color: #002949;
    transition: all 0.3s ease;
    text-decoration: none; }
  button:active, .popup-block.add-to-cart .link-to-cart:active,
  .button:active {
    box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease;
    will-change: padding; }
  button.disabled, .popup-block.add-to-cart .disabled.link-to-cart,
  .button.disabled {
    background: #aaa;
    background: linear-gradient(to top, #aaa, #999);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
    border-color: #484848;
    color: #484848;
    cursor: default;
    pointer-events: none; }

.yashare_text {
  text-align: right; }

html.ie9 * {
  border-radius: 0 !important; }

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  /*pointer-events: none;*/
  position: relative;
  z-index: 1; }
  .wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    max-width: 1200px;
    background: #fff;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    z-index: -1; }

.loaded .wrapper {
  height: auto;
  overflow: hidden;
  pointer-events: all;
  visibility: visible; }

.mobile.menu-visible .overlay,
.tablet.menu-visible .overlay {
  visibility: hidden; }

.mobile .wrapper,
.tablet .wrapper {
  padding: 44px 0 0;
  position: relative; }

.locked .overlay {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
  visibility: visible; }

.icon {
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px 0 0; }

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100; }

.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  pointer-events: none; }

.desktop .mob-icon {
  visibility: hidden; }

.citronLoader {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoader span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  background: url(/assets/images/ajaxloader.gif) 0 0 no-repeat; }

.citronLoaderBig {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2; }

.citronLoaderBig span {
  position: absolute;
  top: 100px;
  left: 48%;
  width: 66px;
  height: 66px;
  background: url(/assets/images/citronloader.gif) 0 0 no-repeat; }

#ajaxedsearch {
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.content-block.filtrovannoe {
  padding: 10px; }

.prd_pages_bottom,
.prd_pages_top {
  color: #000;
  font-size: 13px;
  text-align: right; }
  .prd_pages_bottom > a,
  .prd_pages_top > a {
    display: inline-block;
    padding: 0 4px; }
    .prd_pages_bottom > a.sel_page,
    .prd_pages_top > a.sel_page {
      color: #000;
      text-decoration: none; }

.example {
  background: #07295e;
  color: #fff;
  font-weight: 700;
  display: inline-block; }

.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%; }
  .video-responsive iframe,
  .video-responsive object,
  .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.redz {
  color: #df141e; }

.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0; }
  .radio + label {
    cursor: pointer; }
  .radio:not(checked) {
    position: absolute;
    visibility: hidden; }
    .radio:not(checked) + label {
      position: relative;
      padding: 0 0 0 35px; }
      .radio:not(checked) + label:before {
        border-radius: 50%;
        background: #ccc;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px; }
      .radio:not(checked) + label:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        border-radius: 50%;
        background: #07295e;
        opacity: 0;
        transition: background .2s ease; }
  .radio:checked + label:after {
    opacity: 1; }

.checkbox {
  vertical-align: top; }
  .checkbox + label {
    cursor: pointer;
    user-select: none; }
  .checkbox:not(checked) {
    position: absolute;
    visibility: hidden; }
    .checkbox:not(checked) + label {
      position: relative;
      padding: 0 0 0 24px; }
      .checkbox:not(checked) + label:before {
        border: 1px solid #3d557b;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 5px;
        background: #fff; }
      .checkbox:not(checked) + label:after {
        content: '\f00c';
        color: #07295e;
        font: normal 16px/1 "FontAwesome";
        position: absolute;
        margin-top: -8px;
        top: 50%;
        left: 1px;
        opacity: 0;
        visibility: hidden; }
  .checkbox:checked + label:after {
    opacity: 1;
    transition: opacity 0.2s ease;
    visibility: visible; }

.video-bg {
  position: fixed;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  top: 0; }

.mobile .button-up,
.tablet .button-up {
  right: 10px; }

.button-up {
  position: fixed;
  bottom: 180px;
  right: 100px;
  text-align: center;
  cursor: pointer;
  z-index: 10001;
  opacity: 0;
  transition: opacity 0.3s ease; }
  .button-up .fa {
    display: block; }
  .button-up.shown {
    opacity: 1;
    transition: opacity 0.3s ease; }
    .button-up.shown:hover {
      color: #07295e;
      transition: color 0.3s ease; }

.mobile .button-up.shown,
.tablet .button-up.shown {
  color: linear-gradient(to bottom, #3c557b, #213b63); }

.mobile.video_on .button-up.shown,
.tablet.video_on .button-up.shown {
  color: linear-gradient(to bottom, #3c557b, #213b63); }

.video_on .button-up.shown {
  color: #fff; }

/* Основные стили */
.input-text,
.input-textarea {
  background: #fff;
  border: 1px solid #3d557b;
  border-radius: 5px;
  color: #000;
  display: block;
  font-size: 13px;
  padding: 3px 0.5em 3px;
  outline: none;
  appearance: none; }

.content-block .reg_auth-section .form_block:not(.form_block__last) .form_title {
  position: initial; }

.content-block .reg_auth-section {
  max-width: 260px;
  background: #fff;
  margin: 1em 0 0;
  padding: 0 1em 1em; }

.content-block .form_block.form_block__last {
  margin: 0; }

.content-block .addition-block a {
  margin-right: 5px; }

.input-textarea {
  padding: 0.5em 1em; }

textarea {
  display: block;
  resize: none;
  padding: 0.5em 1em; }

.form_block {
  margin: 1.25em 0 0;
  position: relative;
  text-align: left; }
  .form_block .form_title {
    color: #666;
    font-size: 12px;
    pointer-events: none;
    line-height: 1.5; }
  .form_block.form_block__last {
    padding: 0;
    margin: -1em 0 0; }
    .form_block.form_block__last .button {
      margin: 0.5em 0 0; }
  .form_block:not(.form_block__last) .form_title {
    position: absolute;
    top: 4px;
    left: 0.75em;
    z-index: 10; }

.has-error > label {
  color: rgba(253, 30, 15, 0.7); }

.has-error > .input-text,
.has-error > .input-textarea {
  border-color: rgba(253, 30, 15, 0.7); }

.has-success > .input-text,
.has-success > .input-textarea {
  border-color: rgba(169, 253, 0, 0.7); }

.form_content {
  position: relative; }

.help-block {
  font-size: 11px;
  position: absolute;
  top: 5px;
  right: 1em;
  pointer-events: none; }
  .help-block.form-error {
    color: rgba(253, 30, 15, 0.7); }

.check-agree {
  padding: 0.5em 0;
  margin: 0; }
  .check-agree .checkbox:not(checked) + label:before {
    top: 3px;
    left: 0;
    margin-top: 0; }
  .check-agree .checkbox:not(checked) + label:after {
    top: 1px;
    left: 1px;
    margin-top: 0; }
  .check-agree label {
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
    line-height: 1.333; }
    .check-agree label.has-error {
      color: rgba(253, 30, 15, 0.7); }
  .check-agree .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto; }

.payFlex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around; }

.payType {
  margin: 6px 0;
  border-radius: 5px;
  box-shadow: 0 0 4px -1px #3d557b;
  background: #fff;
  cursor: pointer;
  padding: 10px 2px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  transition: ease all .02s;
  -webkit-transition: ease all .02s;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  .payType.active {
    box-shadow: 0 0 4px 2px #07295e; }
  .payType > div {
    line-height: 35px; }
  .payType > img {
    height: 35px;
    max-width: 70px;
    margin: 0 1em 0 0; }
  .payType .radio:not(checked) + label {
    padding: 0;
    height: 32px;
    width: 32px; }
    .payType .radio:not(checked) + label:after {
      left: 50%;
      margin-left: -5px; }
    .payType .radio:not(checked) + label:before {
      margin-left: -8px;
      left: 50%; }

.ui-datepicker .ui-widget-header {
  background: #07295e;
  color: #fff; }

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important; }

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a {
  color: #07295e !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important; }

.ui-datepicker td.ui-datepicker-today > a {
  color: #fff !important;
  background: #07295e !important;
  border: 1px solid #07295e !important; }

.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid #07295e !important; }

.ui-datepicker th.ui-datepicker-week-end > span {
  color: #07295e !important;
  background: none !important;
  border: 1px solid transparent !important; }

.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: #07295e !important; }

header .container {
  background: #fff; }

header.scrolled {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  padding: 0; }
  header.scrolled .wow {
    visibility: visible !important; }
  header.scrolled .header-bottom .header-contacts {
    padding: 0.8em 1em 0; }
  header.scrolled .header-bottom .logo {
    padding: 0.3em 1em; }
    header.scrolled .header-bottom .logo .logo-img {
      max-width: 80px;
      max-height: 75px; }
    header.scrolled .header-bottom .logo .slogan {
      font-size: 16px;
      padding: 0.4em 0 0 1em; }
  header.scrolled .header-bottom .phones .icon {
    margin: -15px 0 0; }
  header.scrolled .header-bottom .phones-title {
    display: none; }
  header.scrolled .header-bottom .phones-list__item > a {
    line-height: 30px; }
  header.scrolled .header-bottom .mail {
    line-height: 27px; }
    header.scrolled .header-bottom .mail .mail-title {
      display: none; }
    header.scrolled .header-bottom .mail .mail-wrapper {
      margin: 0; }
  header.scrolled .header-bottom .times-block .title {
    display: none; }
  header.scrolled .header-bottom .righ_block .search {
    display: none; }
  header.scrolled .header-bottom .header-cart .cart > .header-cart__title {
    display: none; }
  header.scrolled .header-bottom .header-cart .cart:before {
    font-size: 25px; }
  header.scrolled .header-bottom .call-order.button {
    padding: 0.5em 14px 0; }
  header.scrolled nav .menu_level_1 > li > a {
    padding: 0.5em; }

.header-bottom .logo {
  float: left;
  width: 15%;
  margin: 0;
  padding: 0 1em;
  text-align: center; }
  @media only screen and (max-width: 860px) {
    .header-bottom .logo {
      width: 100%; } }
  .header-bottom .logo a {
    display: block; }
  .header-bottom .logo .logo-img {
    width: 100%;
    max-width: 116px;
    height: 100%;
    max-height: 108px;
    vertical-align: middle; }
  .header-bottom .logo .slogan {
    font-size: 20px;
    font-weight: 900;
    float: left;
    color: #000;
    text-align: center;
    padding: 0.8em 0 0 1em;
    text-transform: uppercase; }

.header-bottom .header-contacts {
  float: right;
  padding: 0.5em 0 0;
  width: 85%; }
  @media only screen and (max-width: 860px) {
    .header-bottom .header-contacts {
      width: 100%; } }

.header-bottom .phones {
  float: left;
  padding: 0 1em 0 2em;
  width: 33%; }
  @media only screen and (max-width: 860px) {
    .header-bottom .phones {
      width: 100%; } }
  @media only screen and (max-width: 860px) {
    .header-bottom .phones {
      padding: 0 1em; } }

.header-bottom .mail {
  float: left;
  padding: 0 1em;
  width: 22%; }
  @media only screen and (max-width: 860px) {
    .header-bottom .mail {
      width: 100%; } }
  .header-bottom .mail .mail-wrapper {
    padding: 0 0 0 2em; }

.header-bottom .righ_block {
  float: right;
  padding: 0 1em;
  width: 45%; }
  @media only screen and (max-width: 860px) {
    .header-bottom .righ_block {
      width: 100%; } }

.header-bottom .call-order-wrapper {
  width: 50%;
  float: left; }
  @media only screen and (max-width: 860px) {
    .header-bottom .call-order-wrapper {
      width: 100%;
      text-align: center; } }

.header-bottom .search {
  float: right;
  width: 90%; }
  @media only screen and (max-width: 860px) {
    .header-bottom .search {
      float: left;
      width: 100%; } }

.header-bottom .auth-block .auth-box {
  float: left;
  padding: 10px 1em 0; }
  .header-bottom .auth-block .auth-box .reg {
    margin-right: 10px; }

/* конец раскладки */
.header-bottom > .container {
  position: relative; }

.header-bottom .phones {
  position: relative; }
  .header-bottom .phones .icon {
    margin: -8px 0 0; }
    .header-bottom .phones .icon:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #07295e;
      z-index: 2; }
    @media only screen and (max-width: 860px) {
      .header-bottom .phones .icon {
        margin: -8px 0 0 15px; } }

.header-bottom .phones-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  @media only screen and (max-width: 860px) {
    .header-bottom .phones-list {
      padding: 0 0 0 1.5em; } }

.header-bottom .phones-list__item > a {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle; }
  @media only screen and (max-width: 860px) {
    .header-bottom .phones-list__item > a {
      line-height: normal; } }

.header-bottom .phones-list__item > span {
  display: inline-block;
  vertical-align: middle;
  color: #07295e;
  padding: 0 0 2px 5px; }
  @media only screen and (max-width: 1200px) {
    .header-bottom .phones-list__item > span {
      display: none; } }
  @media only screen and (max-width: 860px) {
    .header-bottom .phones-list__item > span {
      display: inline-block; } }

.header-bottom .phones-list__item .pref {
  font-weight: normal; }

.header-bottom .mail .mail-wrapper {
  position: relative;
  margin: 0 0 1em; }
  .header-bottom .mail .mail-wrapper .icon:before {
    content: "";
    color: #07295e;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #07295e;
    margin: -13px 0 0 -10px;
    z-index: 2; }
  @media only screen and (max-width: 860px) {
    .header-bottom .mail .mail-wrapper {
      margin: 0; } }

.header-bottom .mail-link {
  font-size: 15px;
  vertical-align: middle;
  white-space: nowrap; }

.header-bottom .phones-title,
.header-bottom .times-block .times-title,
.header-bottom .mail-title {
  font-weight: 600; }

.header-bottom .call-order.button {
  background: none;
  color: #e48a0a;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  border: none; }
  .header-bottom .call-order.button:hover {
    color: #07295e; }
  .header-bottom .call-order.button:active {
    box-shadow: none; }

.header-bottom .times-block .title {
  font-size: 15px;
  margin: 0 0 3px;
  font-weight: 600; }
  @media only screen and (max-width: 768px) {
    .header-bottom .times-block .title {
      padding: 0 0 0 2em; } }

.header-bottom .times {
  padding: 0 0 0 1.5em;
  position: relative;
  white-space: nowrap; }
  @media only screen and (max-width: 768px) {
    .header-bottom .times {
      padding: 0 0 0 2em; } }
  .header-bottom .times > span {
    font-weight: 600; }
  .header-bottom .times:before {
    content: "";
    color: #07295e;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #07295e;
    left: 0;
    margin: -12px 0 0 0;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .header-bottom .times:before {
        margin: -25px 0 0; } }

.header-cart {
  position: relative;
  float: right;
  width: 50%;
  cursor: default;
  text-align: right;
  user-select: none; }
  @media only screen and (max-width: 860px) {
    .header-cart {
      width: 100%; } }
  .header-cart.open .cart.full:before {
    color: #07295e; }
  .header-cart .cart {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding: 3px 0;
    border-radius: 0 5px 5px 0; }
    .header-cart .cart > .header-cart__title-mob {
      display: none; }
    .header-cart .cart > .header-cart__title {
      display: none; }
    .header-cart .cart .summ-block {
      position: relative;
      padding: 0 0 0 60px; }
      .header-cart .cart .summ-block .header_cart-goods-text {
        font-size: 12px; }
    .header-cart .cart > .count {
      position: relative;
      padding: 0 0 0 60px; }
      .header-cart .cart > .count > .count-text {
        font-size: 12px;
        display: inline-block; }
      .header-cart .cart > .count > .count-quantity {
        display: inline-block; }

.desktop .header-cart {
  border-radius: 5px;
  border: 1px solid #df6600;
  box-shadow: 1px 1px 3px -1px #000;
  background: linear-gradient(to bottom, #f0df53, #ff7402); }
  .desktop .header-cart .cart:before {
    content: '';
    position: absolute;
    left: 50px;
    top: 0px;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .desktop .header-cart .cart:after {
    content: '.';
    font-size: 0px;
    line-height: 0px;
    display: block;
    width: 33px;
    height: 31px;
    position: absolute;
    top: 8px;
    left: 7px;
    background: url(../images/sprite2.png) -65px -107px no-repeat;
    z-index: 2; }
  .desktop .header-cart .cart.full {
    cursor: pointer; }
  .desktop .header-cart .cart:not(.empty):hover:before {
    color: #07295e; }

#progressBar {
  height: 3px;
  background: #07295e;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  position: fixed;
  z-index: 9999;
  pointer-events: none; }

/* Вид на мобильных девайсах */
.mobile .menu-button,
.tablet .menu-button {
  background: none;
  border: none;
  cursor: pointer;
  float: left;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  width: 45px;
  height: 44px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
  user-select: none;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out; }
  .mobile .menu-button:after,
  .tablet .menu-button:after {
    background: #b7bbc1;
    border-right: 1px solid #b7bbc1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px; }
  .mobile .menu-button > span,
  .tablet .menu-button > span {
    display: block;
    position: absolute;
    height: 5px;
    width: 28px;
    background: #07295e;
    border-radius: 1px;
    opacity: 1;
    left: 8px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out; }
    .mobile .menu-button > span:nth-child(1),
    .tablet .menu-button > span:nth-child(1) {
      top: 9px;
      transform-origin: left center; }
    .mobile .menu-button > span:nth-child(2),
    .tablet .menu-button > span:nth-child(2) {
      top: 19px;
      transform-origin: left center; }
    .mobile .menu-button > span:nth-child(3),
    .tablet .menu-button > span:nth-child(3) {
      top: 29px;
      transform-origin: left center; }
  .mobile .menu-button.open > span:nth-child(1),
  .tablet .menu-button.open > span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
    left: 12px; }
  .mobile .menu-button.open > span:nth-child(2),
  .tablet .menu-button.open > span:nth-child(2) {
    width: 0;
    opacity: 0; }
  .mobile .menu-button.open > span:nth-child(3),
  .tablet .menu-button.open > span:nth-child(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 12px; }

.mobile .header-top,
.tablet .header-top {
  background: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
  backface-visibility: hidden;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
  will-change: transform;
  z-index: 20; }
  .mobile .header-top .search,
  .tablet .header-top .search {
    float: right;
    padding: 0;
    position: relative;
    height: 44px;
    width: 44px; }
    .mobile .header-top .search:after,
    .tablet .header-top .search:after {
      background: #b7bbc1;
      border-right: 1px solid #b7bbc1;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px; }
    .mobile .header-top .search .search-form.open,
    .tablet .header-top .search .search-form.open {
      transform: translate3d(0, 0, 0);
      visibility: visible; }
  .mobile .header-top .search-icon,
  .tablet .header-top .search-icon {
    height: 44px;
    width: 44px; }
    .mobile .header-top .search-icon:before,
    .tablet .header-top .search-icon:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #b7bbc1;
      margin: -13px 0 0 -7px;
      z-index: 2; }
  .mobile .header-top .search-form,
  .tablet .header-top .search-form {
    background: #fff;
    padding: 7px 44px;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    width: auto;
    max-width: none;
    transform: translate3d(0, -100%, 0);
    transition: all 0.3s ease-out;
    z-index: 10;
    visibility: hidden; }
    .mobile .header-top .search-form .input-text,
    .tablet .header-top .search-form .input-text {
      padding: 5px 0.5em; }
  .mobile .header-top .search-form__button,
  .tablet .header-top .search-form__button {
    width: 44px;
    background: none; }
    .mobile .header-top .search-form__button:before,
    .tablet .header-top .search-form__button:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #e48a0a;
      margin: -12px 0 0 -7px;
      z-index: 2; }
    .mobile .header-top .search-form__button.disabled:before,
    .tablet .header-top .search-form__button.disabled:before {
      color: #999; }
  .mobile .header-top .times-block,
  .tablet .header-top .times-block {
    display: inline-block;
    float: none;
    margin: 0.6em 0 0;
    width: auto; }
    @media only screen and (max-width: 860px) {
      .mobile .header-top .times-block,
      .tablet .header-top .times-block {
        display: none; } }

.mobile .header-bottom,
.tablet .header-bottom {
  border-top: none;
  margin: 0; }
  @media only screen and (max-width: 860px) {
    .mobile .header-bottom .logo,
    .tablet .header-bottom .logo {
      width: 100%;
      max-width: 100%; }
    .mobile .header-bottom .header-contacts,
    .tablet .header-bottom .header-contacts {
      padding: 0 1em;
      width: 100%; } }
  @media only screen and (max-width: 860px) and (max-width: 414px) {
    .mobile .header-bottom .header-contacts,
    .tablet .header-bottom .header-contacts {
      text-align: center;
      margin: 1em 0; } }
  @media only screen and (max-width: 414px) {
    .mobile .header-bottom .logo,
    .tablet .header-bottom .logo {
      padding: 0 1em; } }

.mobile .header-contacts,
.tablet .header-contacts {
  width: 65%;
  padding: 3em 0 0; }
  .mobile .header-contacts .times,
  .tablet .header-contacts .times {
    padding: 0; }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .times,
      .tablet .header-contacts .times {
        position: static; } }
    .mobile .header-contacts .times:before,
    .tablet .header-contacts .times:before {
      margin: -21px 0 0 -25px;
      top: 0; }
      @media only screen and (max-width: 414px) {
        .mobile .header-contacts .times:before,
        .tablet .header-contacts .times:before {
          margin: 0; } }
  .mobile .header-contacts .times-block,
  .tablet .header-contacts .times-block {
    float: left;
    padding: 0 1em 0 2em;
    width: 50%; }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .times-block,
      .tablet .header-contacts .times-block {
        position: relative;
        margin: 0;
        width: 100%;
        line-height: inherit; } }
    .mobile .header-contacts .times-block .title,
    .tablet .header-contacts .times-block .title {
      padding: 0; }
  @media only screen and (max-width: 860px) {
    .mobile .header-contacts .phones-list,
    .tablet .header-contacts .phones-list {
      padding: 0; } }
  .mobile .header-contacts .phones,
  .tablet .header-contacts .phones {
    float: right;
    width: 50%;
    padding: 0 1em 0 2em; }
    .mobile .header-contacts .phones .icon,
    .tablet .header-contacts .phones .icon {
      margin: -14px 0 0; }
      @media only screen and (max-width: 414px) {
        .mobile .header-contacts .phones .icon,
        .tablet .header-contacts .phones .icon {
          margin: -8px 0 0; } }
    @media only screen and (max-width: 414px) {
      .mobile .header-contacts .phones,
      .tablet .header-contacts .phones {
        float: left;
        width: inherit; } }
    .mobile .header-contacts .phones .phones-list__item > a,
    .tablet .header-contacts .phones .phones-list__item > a {
      line-height: inherit; }

.mobile .header-cart,
.tablet .header-cart {
  float: right;
  height: 44px;
  width: 44px;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .mobile .header-cart.open .cart.full,
  .tablet .header-cart.open .cart.full {
    /*@include spriteIcon(0, -585px) {
          margin: -15px 0 0 -15px;
          height: 28px;
          width: 28px;
          }*/ }
  .mobile .header-cart .header-cart__title-mob,
  .tablet .header-cart .header-cart__title-mob {
    display: none; }
  .mobile .header-cart .cart,
  .tablet .header-cart .cart {
    padding: 0;
    width: 100%;
    height: 44px; }
    .mobile .header-cart .cart:after,
    .tablet .header-cart .cart:after {
      background: #b7bbc1;
      border-right: 1px solid #b7bbc1;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px; }
    .mobile .header-cart .cart:before,
    .tablet .header-cart .cart:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #b7bbc1;
      font-size: 34px;
      margin: -26px 0 0 -18px;
      left: 50%;
      z-index: 2; }
  .mobile .header-cart .summ-block,
  .tablet .header-cart .summ-block {
    display: none;
    padding: 0; }
  .mobile .header-cart .header-cart__title,
  .tablet .header-cart .header-cart__title {
    display: none; }
  .mobile .header-cart .count,
  .tablet .header-cart .count {
    background: #fff;
    position: absolute;
    border-radius: 50%;
    height: 16px;
    padding: 0;
    text-align: center;
    width: 16px;
    top: 50%;
    margin: -11px 0 0 -7px;
    left: 50%;
    z-index: 2; }
    .mobile .header-cart .count .count-quantity,
    .tablet .header-cart .count .count-quantity {
      color: #222;
      display: block;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: -1px; }
    .mobile .header-cart .count .count-text,
    .tablet .header-cart .count .count-text {
      display: none; }
    .mobile .header-cart .count .qt,
    .tablet .header-cart .count .qt {
      display: none; }

@media only screen and (max-width: 600px) {
  .header-top .search {
    float: right;
    position: relative;
    width: 4em;
    height: 3em;
    perspective-origin: right center; } }

.owl-carousel {
  display: none;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  visibility: hidden;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    height: 100%;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    will-change: transform; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    user-select: none; }
  .owl-carousel .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0; }
    .owl-carousel .owl-nav.disabled {
      display: none; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
      color: #fff;
      cursor: pointer;
      font: normal 2em/1 "FontAwesome";
      font-weight: bold;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
      .owl-carousel .owl-nav .owl-prev.disabled,
      .owl-carousel .owl-nav .owl-next.disabled {
        opacity: 0.5 !important;
        cursor: default; }
    .owl-carousel .owl-nav .owl-prev {
      left: 0.3em;
      right: auto; }
      .owl-carousel .owl-nav .owl-prev:hover {
        opacity: 0.9; }
    .owl-carousel .owl-nav .owl-next {
      left: auto;
      right: 0.3em; }
      .owl-carousel .owl-nav .owl-next:hover {
        opacity: 0.9; }
  .owl-carousel .owl-dots {
    /*background: $headerTopBgColor;*/
    display: inline-block;
    padding: 0.21426em 1em 0;
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    z-index: 100; }
    .owl-carousel .owl-dots.disabled {
      display: none; }
    @media only screen and (max-width: 768px) {
      .owl-carousel .owl-dots {
        font-size: 12px; } }
    .owl-carousel .owl-dots > .owl-dot {
      display: inline-block;
      zoom: 1; }
      .owl-carousel .owl-dots > .owl-dot.active > span {
        border: 0.35643em solid #e48a0a;
        background: none;
        padding: 0.28568em;
        margin: 0.35711em 0.28568em;
        width: 1.18em;
        height: 1.18em; }
      .owl-carousel .owl-dots > .owl-dot > span {
        border: 0.2851em solid transparent;
        margin: 0.71427em 0.64283em;
        background: #fff;
        display: block;
        transition: opacity 200ms ease;
        border-radius: 2.142854em; }
        @media only screen and (max-width: 768px) {
          .owl-carousel .owl-dots > .owl-dot > span {
            border: 0.25em solid transparent; } }
  .owl-carousel .animated, .owl-carousel header, .owl-carousel header.scrolled, .owl-carousel .catalog-item:hover .product_photo .show_product .photo_hover, .catalog-item:hover .product_photo .show_product .owl-carousel .photo_hover {
    animation-duration: 1000ms;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    animation-name: fadeOut; }
  .owl-carousel .owl-height {
    transition: height 500ms ease-in-out; }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }
  .owl-carousel.owl-loaded {
    display: block;
    visibility: visible; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    display: none; }
  .owl-carousel.owl-drag .owl-item {
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

.slider .owl-stage-outer {
  /*border-radius: $borderRadius;*/ }

.slider.owl-carousel .owl-nav .owl-prev,
.slider.owl-carousel .owl-nav .owl-next {
  font-size: 5em; }
  @media only screen and (max-width: 768px) {
    .slider.owl-carousel .owl-nav .owl-prev,
    .slider.owl-carousel .owl-nav .owl-next {
      font-size: 2em; } }

.slider.owl-carousel .owl-item {
  /* От 1200px и ниже уменьшаем высоту в зависимости от ширины экрана */ }

.slider.owl-carousel .slider-item {
  position: relative;
  height: 100%;
  background: #fff; }
  .slider.owl-carousel .slider-item:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: red;
    z-index: -1; }
  .slider.owl-carousel .slider-item > a,
  .slider.owl-carousel .slider-item > div:not(.item-label) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.slider.owl-carousel .item-label {
  position: absolute;
  padding: 0.5em 1em;
  bottom: 15%;
  left: 5%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px; }
  @media only screen and (max-width: 600px) {
    .slider.owl-carousel .item-label {
      display: none; } }
  .slider.owl-carousel .item-label > .item-label__link,
  .slider.owl-carousel .item-label > p {
    text-decoration: none;
    padding: 0; }
    .slider.owl-carousel .item-label > .item-label__link > p,
    .slider.owl-carousel .item-label > p > p {
      color: #000;
      padding: 0; }
  .slider.owl-carousel .item-label:hover > .item-label__link {
    color: #000; }

.slider-block {
  margin: 0 auto;
  width: 100%;
  background: #fff;
  padding: 1em 0 0; }

.no-js .owl-carousel {
  display: block;
  visibility: visible; }
  .no-js .owl-carousel > div {
    display: none; }
    .no-js .owl-carousel > div:nth-child(1) {
      display: block; }

.mobile .slider-block,
.tablet .slider-block {
  margin: 0.5em auto 0; }
  @media only screen and (max-width: 860px) {
    .mobile .slider-block,
    .tablet .slider-block {
      margin: 0; } }

.mobile .slider.owl-carousel,
.tablet .slider.owl-carousel {
  background: none;
  padding: 0; }

.module-inner .owl-carousel .owl-nav .owl-prev,
.module-inner .owl-carousel .owl-nav .owl-next {
  background: linear-gradient(to bottom, #3c557b, #213b63);
  border-radius: 50%;
  color: #fff;
  line-height: 1em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center; }

.module-inner .owl-carousel .owl-nav .owl-prev {
  left: -0.3em; }

.module-inner .owl-carousel .owl-nav .owl-next {
  right: -0.3em; }

@media only screen and (max-width: 768px) {
  .module-inner .owl-carousel .owl-nav .owl-prev {
    left: 0; }
  .module-inner .owl-carousel .owl-nav .owl-next {
    right: 0; } }

.tablet .module-inner .owl-carousel .owl-nav .owl-prev,
.tablet .module-inner .owl-carousel .owl-nav .owl-next,
.mobile .module-inner .owl-carousel .owl-nav .owl-prev,
.mobile .module-inner .owl-carousel .owl-nav .owl-next {
  /*display: none;*/ }

nav {
  width: 100%;
  position: relative;
  /* Всплывающее меню */ }
  nav > .container {
    background: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
    border-top: 1px solid #07295e;
    border-bottom: 1px solid #07295e; }
  nav .menu_level_1 {
    display: table;
    list-style: none;
    width: 100%; }
    @media only screen and (max-width: 768px) {
      nav .menu_level_1 {
        display: block;
        text-align: center; } }
    nav .menu_level_1 > li {
      display: table-cell;
      position: relative;
      vertical-align: top;
      padding: 0 1em; }
      nav .menu_level_1 > li:first-child {
        padding: 0 1em 0 0; }
      nav .menu_level_1 > li:last-child {
        padding: 0 0 0 1em; }
      @media only screen and (max-width: 768px) {
        nav .menu_level_1 > li {
          display: inline-block; } }
      nav .menu_level_1 > li > a {
        color: #07295e;
        font-weight: 600;
        text-shadow: 0 2px 1px #fff;
        display: block;
        padding: 0.7em 0.5em;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent; }
        @media only screen and (max-width: 768px) {
          nav .menu_level_1 > li > a {
            padding: 0.5em; } }
      nav .menu_level_1 > li.selected {
        cursor: default; }
        nav .menu_level_1 > li.selected > a {
          background: linear-gradient(to bottom, #f0df53, #ff7402);
          border-color: #a15500; }
      nav .menu_level_1 > li + li:before {
        content: '';
        position: absolute;
        background: #07295e;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 50%;
        margin: -3px 0 0 -3px;
        left: 0; }
    nav .menu_level_1 a {
      display: block;
      text-decoration: none; }
  nav .menu_level_2 {
    background: #fff;
    box-shadow: none;
    display: table;
    list-style: none;
    margin: 0 0 0 1em;
    min-width: 220px;
    width: 100%;
    padding: 0;
    position: absolute;
    opacity: 0;
    top: 100%;
    left: 0;
    z-index: 999;
    text-align: left;
    text-transform: none;
    visibility: hidden;
    border-radius: 0 0 5px 5px; }
    nav .menu_level_2 > li {
      display: table-cell;
      padding: 0.5em 1em; }
      nav .menu_level_2 > li .menu-item__photo {
        float: left;
        width: 50%; }
        nav .menu_level_2 > li .menu-item__photo > img {
          display: block;
          height: auto;
          max-width: 100px;
          width: 100%; }
      nav .menu_level_2 > li .menu-item__description {
        float: right;
        width: 50%; }
        nav .menu_level_2 > li .menu-item__description > span {
          font-size: 12px; }
      nav .menu_level_2 > li .menu-item__header > span {
        color: #323232;
        font-size: 12px;
        vertical-align: top; }
      nav .menu_level_2 > li > a {
        color: #000;
        display: block; }
      nav .menu_level_2 > li ul {
        display: block;
        list-style: none;
        padding: 0.5em; }
        nav .menu_level_2 > li ul > li > a {
          color: #000;
          display: block;
          white-space: nowrap; }
          nav .menu_level_2 > li ul > li > a:hover {
            color: #e48a0a; }
        nav .menu_level_2 > li ul > li.selected > a {
          color: #e48a0a; }

.desktop nav .menu_level_1 {
  position: relative;
  width: 100%; }
  .desktop nav .menu_level_1 > li {
    /* Показываем всплывашку на ховер */ }
    .desktop nav .menu_level_1 > li.subcat:hover .menu_level_2 {
      opacity: 1;
      transition: opacity 0.3s ease, transform 0.3s ease-out;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      box-shadow: inset 0 1px 0px 0 #fff;
      border: 1px solid #07295e; }
    .desktop nav .menu_level_1 > li:not(.selected):hover > a {
      color: #e48a0a; }

.desktop nav .menu_level_2 {
  display: table-row;
  left: 0;
  transform-origin: center;
  transform: translate3d(0, 1em, 0); }
  .desktop nav .menu_level_2 > li {
    float: left;
    width: 100%; }
    .desktop nav .menu_level_2 > li.selected > a {
      color: #e48a0a; }
    .desktop nav .menu_level_2 > li:not(.selected) > a:hover {
      color: #e48a0a; }

.desktop nav .menu_level_3 > li:not(.selected):hover > a {
  color: #e48a0a; }

.desktop nav .search {
  float: right;
  margin: 3px 0 0;
  padding: 0 1em;
  width: 250px; }
  @media only screen and (max-width: 992px) {
    .desktop nav .search {
      margin: 0;
      width: 100%; } }

.desktop nav .menu-wrapper {
  float: left;
  width: 100%; }

.nav-left {
  /*padding: 0 1em;*/ }

.nav-left {
  position: relative; }
  .nav-left .menu_level_1 {
    display: block; }
    .nav-left .menu_level_1 > li {
      list-style: none;
      transition: all 0.3s ease;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
      .nav-left .menu_level_1 > li:not(:last-child) {
        border-bottom: 1px solid #eaeaea; }
      .nav-left .menu_level_1 > li:first-child {
        border-top: none; }
      .nav-left .menu_level_1 > li:last-child {
        border-bottom: none; }
      .nav-left .menu_level_1 > li > a {
        display: block;
        color: #000;
        padding: 5px 1em;
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease; }
        .nav-left .menu_level_1 > li > a:after:before {
          content: "";
          color: #07295e;
          font-family: "FontAwesome";
          font-size: 18px;
          position: absolute;
          margin: -11px 0 0 -10px;
          top: 50%;
          left: 50%;
          color: #333;
          font-size: 12px;
          margin-top: -7px;
          top: 50%;
          left: auto;
          right: 1em;
          transition: 0.1s ease 0.07s;
          z-index: 2; }
      .nav-left .menu_level_1 > li.subcats_exists:not(.selected):hover > a:after {
        right: 1em;
        transform-origin: left bottom;
        transform: rotate(90deg); }
      .nav-left .menu_level_1 > li.selected {
        cursor: default; }
        .nav-left .menu_level_1 > li.selected > a {
          background: #f39919;
          color: #fff;
          text-decoration: none; }
        .nav-left .menu_level_1 > li.selected.subcats_exists > a:after {
          color: #fff;
          transform: rotate(90deg); }
  .nav-left .menu_level_2 {
    background: #fff;
    display: block;
    padding: 0; }
    .nav-left .menu_level_2 > li {
      list-style: none; }
      .nav-left .menu_level_2 > li:not(:last-child) {
        border-bottom: 1px solid #eaeaea; }
      .nav-left .menu_level_2 > li > a {
        color: #000;
        display: block;
        font-size: 13px;
        padding: 5px 1em;
        text-decoration: none; }
      .nav-left .menu_level_2 > li:hover > a {
        color: #e48a0a;
        text-decoration: underline; }
      .nav-left .menu_level_2 > li.selected > a {
        color: #e48a0a;
        text-decoration: none; }
  .nav-left .menu_level_3 {
    padding: 0.5em 1em 0.75em 2em; }
    .nav-left .menu_level_3 > li {
      list-style: disc;
      padding: 0.2em 0; }
      .nav-left .menu_level_3 > li > a {
        color: #000;
        display: block;
        text-decoration: none; }
      .nav-left .menu_level_3 > li.selected {
        color: #07295e; }
        .nav-left .menu_level_3 > li.selected > a {
          color: #07295e; }
        .nav-left .menu_level_3 > li.selected:hover {
          color: #07295e; }
          .nav-left .menu_level_3 > li.selected:hover > a {
            color: #07295e; }
      .nav-left .menu_level_3 > li:hover {
        color: #e48a0a;
        transition: color 0.3s ease; }
        .nav-left .menu_level_3 > li:hover > a {
          color: #e48a0a; }

/* ховеры только на десктопах */
.desktop .nav-left .menu_level_1 > li:not(.selected):hover {
  background: #07295e; }
  .desktop .nav-left .menu_level_1 > li:not(.selected):hover > a {
    color: #fff; }
    .desktop .nav-left .menu_level_1 > li:not(.selected):hover > a:after {
      right: 0.8em; }

.mobile.menu-visible nav,
.tablet.menu-visible nav {
  left: 0;
  transition: left 0.2s ease-out; }

.mobile nav,
.tablet nav {
  background: url(../images/footer-fon.jpg) 0 0;
  position: fixed;
  top: 0;
  left: -100%;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 260px;
  z-index: 1;
  text-align: left;
  transition: transform 0s ease-out, visibility 0s;
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch; }
  .mobile nav > .container,
  .tablet nav > .container {
    background: none;
    border: 0; }
  .mobile nav.show-menu,
  .tablet nav.show-menu {
    display: block !important;
    height: calc(100vh - 3em);
    transform: translate3d(0, 0, 0) !important;
    transition: transform 0.4s ease-out;
    visibility: visible !important; }
  .mobile nav .arrow,
  .tablet nav .arrow {
    height: 38px;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    transition: transform 0.3s ease;
    transform: translate3d(0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    will-change: transform;
    z-index: 10; }
    .mobile nav .arrow:before,
    .tablet nav .arrow:before {
      background: url("/assets/images/sprite.svg") 0px -495px no-repeat;
      content: '';
      position: absolute;
      margin-left: -10px;
      margin-top: -4px;
      height: 8px;
      width: 14px;
      left: 50%;
      top: 50%;
      transition: transform 0.3s ease;
      will-change: transform; }
  .mobile nav .menu_level_1,
  .tablet nav .menu_level_1 {
    display: block;
    padding: 0; }
    .mobile nav .menu_level_1 > li,
    .tablet nav .menu_level_1 > li {
      display: block;
      width: auto;
      padding: 0; }
      .mobile nav .menu_level_1 > li + li:before,
      .tablet nav .menu_level_1 > li + li:before {
        display: none; }
      .mobile nav .menu_level_1 > li.selected > .mob-icon,
      .tablet nav .menu_level_1 > li.selected > .mob-icon {
        color: #222 !important; }
      .mobile nav .menu_level_1 > li.selected.subcat .menu_level_2,
      .tablet nav .menu_level_1 > li.selected.subcat .menu_level_2 {
        display: block; }
      .mobile nav .menu_level_1 > li.open .arrow,
      .tablet nav .menu_level_1 > li.open .arrow {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease; }
        .mobile nav .menu_level_1 > li.open .arrow.rotate:before,
        .tablet nav .menu_level_1 > li.open .arrow.rotate:before {
          transform: rotate(180deg);
          transition: transform 0.3s ease; }
      .mobile nav .menu_level_1 > li > a,
      .tablet nav .menu_level_1 > li > a {
        color: #fff;
        padding: 0.5em 1em 0.5em 1.5em;
        text-align: left;
        text-shadow: none; }
      .mobile nav .menu_level_1 > li:not(.selected) > a:hover,
      .tablet nav .menu_level_1 > li:not(.selected) > a:hover {
        color: #e48a0a; }
    .mobile nav .menu_level_1 .mob-icon,
    .tablet nav .menu_level_1 .mob-icon {
      top: 0.8em;
      left: 1em; }
  .mobile nav .menu_level_2,
  .tablet nav .menu_level_2 {
    background: rgba(255, 255, 255, 0.3);
    display: none;
    position: static;
    overflow: hidden;
    opacity: 1;
    transition: all 0s;
    visibility: visible;
    will-change: transform;
    z-index: 5;
    margin: 0;
    border-radius: 0; }
    .mobile nav .menu_level_2 > li,
    .tablet nav .menu_level_2 > li {
      display: block;
      padding: 0; }
      .mobile nav .menu_level_2 > li > a,
      .tablet nav .menu_level_2 > li > a {
        padding: 0.5em 1em 0.6em 2em;
        color: #000; }
      .mobile nav .menu_level_2 > li.selected > a,
      .tablet nav .menu_level_2 > li.selected > a {
        color: #fff; }
      .mobile nav .menu_level_2 > li.selected.subcat .menu_level_3,
      .tablet nav .menu_level_2 > li.selected.subcat .menu_level_3 {
        display: block; }
    .mobile nav .menu_level_2 .mob-icon,
    .tablet nav .menu_level_2 .mob-icon {
      display: none; }
  .mobile nav .menu_level_3,
  .tablet nav .menu_level_3 {
    background: rgba(255, 255, 255, 0.2);
    display: none; }
    .mobile nav .menu_level_3 > li,
    .tablet nav .menu_level_3 > li {
      display: block;
      padding: 0; }
      .mobile nav .menu_level_3 > li > a,
      .tablet nav .menu_level_3 > li > a {
        padding: 0.3em 1em 0.3em 3em;
        color: #000;
        font-size: 13px; }
      .mobile nav .menu_level_3 > li.selected > a,
      .tablet nav .menu_level_3 > li.selected > a {
        color: #fff; }

.mobile .nav-left,
.tablet .nav-left {
  padding: 0; }

.mobile nav .mail .mail-wrapper,
.tablet nav .mail .mail-wrapper {
  position: relative;
  text-align: center;
  padding: 0.5em 0; }
  .mobile nav .mail .mail-wrapper .icon,
  .tablet nav .mail .mail-wrapper .icon {
    left: 10px; }
    .mobile nav .mail .mail-wrapper .icon:before,
    .tablet nav .mail .mail-wrapper .icon:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #fff;
      z-index: 2; }
  .mobile nav .mail .mail-wrapper .mail-link,
  .tablet nav .mail .mail-wrapper .mail-link {
    color: #fff; }
    .mobile nav .mail .mail-wrapper .mail-link:hover,
    .tablet nav .mail .mail-wrapper .mail-link:hover {
      color: #e48a0a; }

.mobile nav .call-order-wrapper,
.tablet nav .call-order-wrapper {
  text-align: center;
  margin: 0 0 1em; }

/* Левое меню */
.mobile .nav-left .menu_level_1,
.tablet .nav-left .menu_level_1 {
  display: block; }
  .mobile .nav-left .menu_level_1 > li,
  .tablet .nav-left .menu_level_1 > li {
    position: relative; }
    .mobile .nav-left .menu_level_1 > li .mob-icon,
    .tablet .nav-left .menu_level_1 > li .mob-icon {
      margin-top: -7px;
      top: 19px;
      left: 0.5em;
      z-index: 1; }
    .mobile .nav-left .menu_level_1 > li > a,
    .tablet .nav-left .menu_level_1 > li > a {
      padding: 5px 1em 5px 2em;
      border-radius: 5px; }
    .mobile .nav-left .menu_level_1 > li.selected .mob-icon,
    .tablet .nav-left .menu_level_1 > li.selected .mob-icon {
      color: #fff !important; }

.feedback {
  padding: 1em; }

.feedback-form .feedback-col-6 {
  float: left;
  padding: 0 0.5em 0 0;
  width: 60%; }
  .feedback-form .feedback-col-6.last {
    padding: 0 0 0 0.5em;
    width: 40%; }
    .feedback-form .feedback-col-6.last .feedback-block {
      margin: 0 0 1em; }

.feedback-form .feedback-button-block {
  float: left;
  margin: 0;
  width: 100%; }
  .feedback-form .feedback-button-block .form_title {
    position: static; }
  .feedback-form .feedback-button-block .form_content {
    margin: 0.5em 0 0; }

.feedback-form input {
  width: 100%; }
  .feedback-form input.button {
    width: auto; }

.feedback-form textarea {
  resize: none;
  margin: 0 0 0.5em;
  height: 152px;
  width: 100%; }

/* Раскладка футера */
.footer-top .contacts {
  float: left;
  padding: 0 1em;
  width: 33.333%; }
  .footer-top .contacts .logo {
    display: inline-block;
    margin: 0.5em 0; }
    .footer-top .contacts .logo a {
      display: inline-block; }
    .footer-top .contacts .logo .logo-img {
      float: left;
      width: 100%;
      max-width: 116px;
      height: 100%;
      max-height: 108px;
      vertical-align: middle; }
      @media only screen and (max-width: 768px) {
        .footer-top .contacts .logo .logo-img {
          display: block;
          float: none;
          margin: 0 auto; } }
  @media only screen and (max-width: 768px) {
    .footer-top .contacts {
      width: 100%;
      text-align: center; }
      .footer-top .contacts > div {
        display: block; }
      .footer-top .contacts .logo {
        width: 100%; }
      .footer-top .contacts .phone > ul > li {
        display: inline-block; }
        .footer-top .contacts .phone > ul > li:first-child {
          margin: 0 10px 0 0; } }
  @media only screen and (max-width: 768px) and (max-width: 768px) {
    .footer-top .contacts .phone > ul > li {
      display: block; }
    .footer-top .contacts .icon {
      left: 0; } }

.footer-top .footer-menu {
  float: right;
  padding: 0 1em;
  width: 66.666%; }
  @media only screen and (max-width: 768px) {
    .footer-top .footer-menu {
      width: 100%; } }

.footer-bottom .payments {
  float: left;
  padding: 0 1em;
  width: 25%; }

.footer-bottom .social-block {
  float: left;
  padding: 0 1em;
  width: 45%; }

.footer-bottom .counters {
  float: right;
  padding: 0 1em;
  width: 30%; }

/* Оформление */
.footer-top {
  background: url(../images/footer-fon.jpg) 0 0; }
  .footer-top .footer-menu {
    margin: 1.5em 0 0; }
    .footer-top .footer-menu .title {
      color: #dbdfe6;
      font-weight: bold;
      margin: 0 0 0.5em;
      text-transform: uppercase; }
    .footer-top .footer-menu .ishop,
    .footer-top .footer-menu .text {
      float: left;
      width: 50%; }
      @media only screen and (max-width: 414px) {
        .footer-top .footer-menu .ishop,
        .footer-top .footer-menu .text {
          width: 100%;
          text-align: center;
          margin: 0 0 1em; } }
      .footer-top .footer-menu .ishop .item > a,
      .footer-top .footer-menu .text .item > a {
        color: #fff;
        text-decoration: none;
        line-height: 1.666; }
        .footer-top .footer-menu .ishop .item > a:hover,
        .footer-top .footer-menu .text .item > a:hover {
          color: #e48a0a; }
      .footer-top .footer-menu .ishop .item.selected > a,
      .footer-top .footer-menu .text .item.selected > a {
        color: #e48a0a;
        cursor: default;
        text-decoration: none; }
      .footer-top .footer-menu .ishop .item.more > a,
      .footer-top .footer-menu .text .item.more > a {
        color: #e48a0a;
        text-decoration: underline; }
        .footer-top .footer-menu .ishop .item.more > a:hover,
        .footer-top .footer-menu .text .item.more > a:hover {
          color: #e48a0a; }
  .footer-top .adres {
    margin: 0 0 0.5em;
    position: relative;
    padding: 0 1em 0 2em; }
    .footer-top .adres .icon {
      left: 4px; }
      .footer-top .adres .icon:before {
        content: "";
        color: #07295e;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #dce0e7;
        z-index: 2; }
    .footer-top .adres a {
      color: #fff; }
      .footer-top .adres a:hover {
        color: #e48a0a; }
  .footer-top .phone {
    margin: 0 0 0.5em;
    padding: 0 1em 0 2em;
    position: relative;
    color: #fff; }
    .footer-top .phone .icon {
      vertical-align: top; }
      .footer-top .phone .icon:before {
        content: "";
        color: #07295e;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #dce0e7;
        z-index: 2; }
    .footer-top .phone > ul {
      display: inline-block;
      list-style: none; }
      .footer-top .phone > ul > li {
        font-size: 15px;
        font-weight: bold; }
        .footer-top .phone > ul > li .pref {
          font-weight: normal; }
  .footer-top .mail {
    margin: 0 0 0.5em;
    position: relative;
    padding: 0 1em 0 2em; }
    .footer-top .mail .icon:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #dce0e7;
      z-index: 2; }
    .footer-top .mail a {
      color: #fff; }
      .footer-top .mail a:hover {
        color: #e48a0a; }

.footer-bottom {
  background: url(../images/footer-fon.jpg) 0 0; }
  @media only screen and (max-width: 600px) {
    .footer-bottom .container {
      text-align: center; }
      .footer-bottom .container > div {
        width: 100%; } }
  .footer-bottom .payments {
    margin: 7px 0 0; }
    .footer-bottom .payments > img {
      max-width: 290px;
      width: 100%; }
  .footer-bottom .social-block {
    margin: 0.5em 0;
    text-align: center; }
  .footer-bottom .social-block__item {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin: 0 0.5em 0 0;
    background: #dbdfe6; }
    .footer-bottom .social-block__item:hover {
      background: #e48a0a; }
      .footer-bottom .social-block__item:hover > a:before {
        color: #fff; }
    .footer-bottom .social-block__item > a {
      height: 25px;
      width: 25px;
      display: inline-block;
      position: relative;
      line-height: 25px;
      text-decoration: none;
      vertical-align: middle; }
      .footer-bottom .social-block__item > a:before {
        content: '';
        color: #07295e;
        font-family: "FontAwesome";
        font-size: 18px;
        position: absolute;
        margin: -11px 0 0 -10px;
        top: 50%;
        left: 50%;
        color: #07295e;
        font-weight: 300;
        font-size: 1em;
        margin: -12px auto 0;
        left: 0;
        right: 0;
        z-index: 2; }
      .footer-bottom .social-block__item > a.si1:before {
        content: '\f189'; }
      .footer-bottom .social-block__item > a.si2:before {
        content: '\f263'; }
      .footer-bottom .social-block__item > a.si3:before {
        content: '\f09a'; }
      .footer-bottom .social-block__item > a.si4:before {
        content: '\f099'; }
      .footer-bottom .social-block__item > a.si5:before {
        content: '\f1a0'; }
      .footer-bottom .social-block__item > a.si6:before {
        content: '\f1fa'; }
      .footer-bottom .social-block__item > a.si7:before {
        content: '\f167'; }
      .footer-bottom .social-block__item > a.si8:before {
        content: '\f16d'; }
  .footer-bottom .counters {
    margin: 0.25em 0 0;
    text-align: right; }
    @media only screen and (max-width: 768px) {
      .footer-bottom .counters {
        text-align: center; } }
    .footer-bottom .counters > a {
      display: inline-block;
      vertical-align: middle; }
    .footer-bottom .counters > .citron-logo__link {
      display: inline-block;
      max-width: 92px;
      width: 100%;
      position: relative;
      vertical-align: middle; }
      .footer-bottom .counters > .citron-logo__link:hover .citron-logo--hover {
        opacity: 1;
        visibility: visible; }
    .footer-bottom .counters .citron-logo--hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
      visibility: hidden; }

.map {
  float: left;
  width: 10%;
  margin: 0.5em 0;
  padding: 0 1em; }
  @media only screen and (max-width: 600px) {
    .map {
      width: 100%; } }

#landing-list .module-header {
  max-width: calc(1200px - 2em);
  margin: 0 auto 1em; }

.landing-list__item {
  margin: 0 0 3em;
  padding: 3em 0;
  background: url(../images/footer-fon.jpg) 0 0;
  border-bottom: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }
  .landing-list__item-title {
    padding: 0 0 1em; }
    .landing-list__item-title span {
      display: block;
      font-size: 20px;
      font-weight: 700; }
  .landing-list__item-image {
    background: #fff;
    padding: 1em;
    border: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }
  .landing-list__item-link {
    text-align: right; }

/* Айшоп  */
/* Выбор валюты */
.currency-list {
  display: block;
  list-style: none;
  margin: 0;
  vertical-align: middle; }
  .currency-list > li {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 6px 0 0;
    width: 36px;
    text-align: center;
    padding: 8px 0 5px;
    height: 38px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
    /* Всплывающая подсказка */ }
    .currency-list > li:after {
      background: #e48a0a;
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      font-size: 11px;
      color: #fff;
      display: block;
      padding: 0.3em 0.6em 0.4em;
      visibility: hidden;
      white-space: nowrap;
      z-index: 10; }
    .currency-list > li:not(.selected):after {
      content: 'Выбрать'; }
    .currency-list > li.selected {
      color: #e48a0a;
      cursor: default; }
      .currency-list > li.selected:after {
        content: 'Текущая валюта'; }
    .currency-list > li:last-child {
      margin: 0; }
    .currency-list > li.rub:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 24px;
      margin: -17px 0 0 -6px;
      z-index: 2; }
    .currency-list > li.rub:not(.selected):hover:before {
      color: #e48a0a; }
    .currency-list > li.rub.selected:before {
      color: #e48a0a; }
    .currency-list > li.dol:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 21px;
      margin: -16px 0 0 -6px;
      z-index: 2; }
    .currency-list > li.dol:not(.selected):hover:before {
      color: #e48a0a; }
    .currency-list > li.dol.selected:before {
      color: #e48a0a; }

.desktop .currency-list > li:hover:after {
  opacity: 1;
  transition: opacity 0.3s ease 0.2s;
  visibility: visible; }

.module.ajaxflowcart {
  background: #2c2a7d;
  overflow: visible;
  margin: 0; }

.ajaxflowcart-block {
  background: #fff;
  border-radius: 5px;
  box-shadow: 6px 8px 32px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 110%;
  transform: translate3d(0, -100%, 0);
  visibility: hidden;
  width: 380px;
  z-index: 10;
  border: 1px solid #3d557b; }
  @media only screen and (max-width: 860px) {
    .ajaxflowcart-block {
      width: 100%; } }
  .ajaxflowcart-block .price {
    font-weight: bold;
    color: #e48a0a; }
  .ajaxflowcart-block .currency {
    font-size: 12px; }
  .ajaxflowcart-block .ajaxflowcart_kill {
    cursor: pointer;
    padding: 0;
    width: 33px; }
    .ajaxflowcart-block .ajaxflowcart_kill > span {
      display: block;
      color: #07295e;
      cursor: pointer;
      font-family: Arial;
      font-size: 14px;
      line-height: 2.3;
      width: 34px;
      height: 34px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .ajaxflowcart-block .ajaxflowcart_photo-img {
    display: block;
    max-width: 45px; }
  .ajaxflowcart-block .ajaxflowcart_photo {
    width: 55px; }
    .ajaxflowcart-block .ajaxflowcart_photo > a {
      display: block; }
  .ajaxflowcart-block .ajaxflowcart_title {
    text-align: left;
    width: 138px; }
    .ajaxflowcart-block .ajaxflowcart_title > a {
      font-size: 13px; }
    .ajaxflowcart-block .ajaxflowcart_title .prod-params {
      font-size: 12px; }
  .ajaxflowcart-block .ajaxflowcart_count {
    color: #333;
    width: 43px; }
    .ajaxflowcart-block .ajaxflowcart_count > span {
      font-size: 13px; }
  .ajaxflowcart-block .ajaxflowcart_price {
    width: 101px; }
  @media only screen and (max-width: 380px) {
    .ajaxflowcart-block {
      width: 100%; }
      .ajaxflowcart-block .currency {
        color: #333;
        font-size: 11px;
        width: 6px;
        overflow: hidden; }
      .ajaxflowcart-block .ajaxflowcart_price {
        padding: 5px 0.5em 5px 5px;
        width: 67px; }
      .ajaxflowcart-block .price {
        font-size: 14px; }
      .ajaxflowcart-block .ajaxflowcart_title {
        width: auto; }
      .ajaxflowcart-block .ajaxflowcart_count {
        width: 39px; }
        .ajaxflowcart-block .ajaxflowcart_count > span {
          font-size: 10px; } }

.tablet.flowcart-visible .ajaxflowcart-block,
.mobile.flowcart-visible .ajaxflowcart-block {
  /*opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);*/ }

.tablet .ajaxflowcart-block,
.mobile .ajaxflowcart-block {
  box-shadow: -6px 8px 32px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  top: 44px;
  height: calc(100vh - 44px);
  transform: translate3d(100%, 0, 0);
  transition: all 0.3s ease;
  z-index: 1002;
  -webkit-overflow-scrolling: touch; }

.ajaxflowcart-table {
  table-layout: fixed;
  width: 100%; }
  .ajaxflowcart-table td {
    padding: 5px;
    border-top: 1px solid #eee; }
  .ajaxflowcart-table tr:first-child > td {
    border-top: none; }

.mobile .ajaxflowcart-table,
.tablet .ajaxflowcart-table {
  margin: 0 0 4em; }

/* Раскладка */
.product .product_left_block {
  float: left;
  position: relative;
  margin: 0 2em 0 0;
  width: calc(50% - 1em); }

.product .product_mid_block {
  float: right;
  width: calc(50% - 1em); }

@media only screen and (max-width: 768px) {
  .product .product_left_block {
    margin: 0 1em 0 0;
    width: calc(50% - 0.5em); }
  .product .product_mid_block {
    width: calc(50% - 0.5em); } }

@media only screen and (max-width: 480px) {
  .product .product_left_block {
    margin: 0 0 1em;
    width: 100%; }
  .product .product_mid_block {
    width: 100%; } }

/* Оформление */
.product {
  margin: 2em 0; }
  .product .product_top {
    margin: 0 0 3em; }
  .product .product-header {
    position: relative; }
  .product .prod_title {
    display: inline-block;
    color: #07295e;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
  .product .product_photo > a {
    display: block;
    outline: none; }
    .product .product_photo > a > img {
      display: block;
      height: auto;
      max-width: 100%;
      width: 100%;
      border-radius: 5px; }
  .product.--hidden {
    display: none; }
  .product .compare {
    text-align: left; }
  .product .compare_box {
    margin: 0 4px 0 0;
    padding: 0; }
  .product .short_description {
    margin: 0.5em 0; }
    .product .short_description * {
      padding: 0; }
    .product .short_description ul {
      list-style: none; }
  .product .characters-table__row {
    color: #000;
    font-size: 14px; }
    .product .characters-table__row > b {
      font-size: 12px; }
  .product .characters-table .characters-table__row {
    color: #000;
    line-height: 1.75;
    position: relative;
    z-index: 1; }
    .product .characters-table .characters-table__row a {
      color: #07295e; }
      .product .characters-table .characters-table__row a:hover {
        color: #e48a0a; }
    .product .characters-table .characters-table__row > b {
      float: left;
      padding: 0 1em 0 0; }
      .product .characters-table .characters-table__row > b:before {
        border-bottom: 1px dashed #3d557b;
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        top: 62%;
        left: 0;
        right: 0;
        z-index: -1; }
    .product .characters-table .characters-table__row > span {
      float: right;
      padding: 0 0 0 1em; }
    .product .characters-table .characters-table__row > span,
    .product .characters-table .characters-table__row > b {
      background: #fff;
      line-height: 1.75;
      z-index: 10; }
  .product .prod_dop_option > b {
    font-weight: normal; }
  .product .prod_dop_option.rating_block {
    color: #666; }
    .product .prod_dop_option.rating_block .rate_results {
      color: #000;
      font-size: 13px;
      float: right;
      padding: 5px 0 5px 10px; }
    .product .prod_dop_option.rating_block > b {
      color: #000;
      font-weight: normal;
      line-height: 1.75;
      position: relative;
      z-index: 10; }
  .product .product-prod_prices {
    margin: 0.5em 0 0; }
  .product .prod_big_price {
    display: inline-block;
    margin: 0 1em 0 0; }
    .product .prod_big_price > span {
      font-size: 24px;
      font-weight: bold; }
  .product .prod_price_old {
    display: inline-block; }
    .product .prod_price_old > span {
      color: #666;
      font-size: 14px;
      text-decoration: line-through; }
  @media only screen and (max-width: 768px) {
    .product {
      padding: 1em 0 0; } }

.kolvo_buy_button_add {
  margin: 1em 0 0; }
  .kolvo_buy_button_add .kolvo {
    float: left;
    margin: 0 1em 0 0;
    padding: 3px 0;
    width: 92px;
    user-select: none;
    border: 1px solid #e48a0a;
    background: #fff;
    border-radius: 5px; }
    .kolvo_buy_button_add .kolvo .input-text {
      display: inline-block;
      padding: 3px 0.25em 3px;
      text-align: center;
      vertical-align: middle;
      width: 34px;
      border-color: #e48a0a; }
  .kolvo_buy_button_add .btn_add {
    float: left; }
  .kolvo_buy_button_add .kolvo_arrow {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px; }
    .kolvo_buy_button_add .kolvo_arrow:hover {
      background: #fff;
      transition: background 0.3s ease; }

.fav-block .catalog-item {
  float: left;
  margin: 0 2em 2em 0;
  width: calc(33.333% - 1.34em); }
  .fav-block .catalog-item:nth-child(3n + 3) {
    margin: 0 0 2em; }

.fav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  vertical-align: text-bottom; }
  .fav-link:before {
    content: "";
    color: #07295e;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    color: #000;
    text-indent: 0;
    z-index: 2; }
  .fav-link.added:before {
    color: #d91c6b; }
  .fav-link:hover:before {
    color: #d91c6b;
    transition: color 0.2s ease; }

/* Ярлыки новинки/хит/скидка */
.product-labels {
  position: absolute;
  right: 1px;
  z-index: 1; }
  .product-labels > span {
    color: #fff;
    cursor: default;
    display: block;
    font-size: 15px;
    margin: 0 0 4px;
    padding: 2px 6px;
    position: relative;
    height: 26px;
    text-align: center;
    user-select: none; }
    .product-labels > span:hover {
      cursor: pointer; }
    .product-labels > span:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 13px solid transparent;
      position: absolute;
      top: 0;
      left: -11px;
      bottom: 0; }
  .product-labels .label-new {
    background: #e54239; }
    .product-labels .label-new:before {
      border-right-color: #e54239; }
  .product-labels .label-spec {
    background: #f19000; }
    .product-labels .label-spec:before {
      border-right-color: #f19000; }
  .product-labels .label-hit {
    background: #ffcd1f;
    color: #947711; }
    .product-labels .label-hit:before {
      border-right-color: #ffcd1f; }

.product_dop_photo_slider .dop-photo__thumb {
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #3d557b; }
  .product_dop_photo_slider .dop-photo__thumb > a {
    display: block; }
  .product_dop_photo_slider .dop-photo__thumb > img {
    display: block;
    width: 100%; }

.product_dop_photo_slider > .owl-carousel {
  padding: 0 1.5em;
  margin: 1em 0 0; }
  .product_dop_photo_slider > .owl-carousel > .owl-stage-outer {
    padding: 1px 0; }
  .product_dop_photo_slider > .owl-carousel > .owl-nav {
    perspective-origin: center; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev,
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      color: #07295e;
      font-size: 3em;
      height: 46px;
      opacity: 1;
      padding: 0;
      position: absolute;
      width: 15px;
      top: 0; }
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev:hover,
      .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next:hover {
        opacity: 0.5;
        transition: opacity 0.2s ease; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-prev {
      left: 0; }
    .product_dop_photo_slider > .owl-carousel > .owl-nav .owl-next {
      right: 0; }

/*ВКЛАДКИ*/
.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 1em 0 2em; }
  .product_dop_modes .product_dop_modes_navigation {
    padding: 0;
    position: relative;
    height: 34px;
    z-index: 12; }
    .product_dop_modes .product_dop_modes_navigation > li {
      position: relative;
      display: block;
      float: left;
      font-size: 17px;
      color: #000;
      font-style: normal;
      height: 35px;
      line-height: 34px;
      padding: 0 20px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 5px 5px 0 0; }
      .product_dop_modes .product_dop_modes_navigation > li:hover {
        color: #fff;
        background: #e48a0a;
        transition: background 0.3s ease; }
      .product_dop_modes .product_dop_modes_navigation > li.mod_selected {
        cursor: default;
        color: #fff;
        background: #07295e; }
  .product_dop_modes .product_dop_modes_content {
    border-top: 1px solid #3d557b;
    border-bottom: 1px solid #3d557b;
    position: relative;
    z-index: 10;
    padding: 1em;
    text-align: justify;
    margin: 0 0 1em;
    border-radius: 5px; }
    .product_dop_modes .product_dop_modes_content li,
    .product_dop_modes .product_dop_modes_content p {
      color: #382507;
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content a {
      font-size: 14px; }
    .product_dop_modes .product_dop_modes_content .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 1em; }

/* Отзывы продукта */
.product-comments-form {
  float: left;
  max-width: 300px;
  width: 100%; }
  .product-comments-form .form_title {
    position: static; }
  .product-comments-form .form_block {
    margin: 0 0 1em; }
  .product-comments-form .input-textarea,
  .product-comments-form .input-text {
    display: block;
    padding: 3px 0.5em 3px;
    width: 100%; }
  .product-comments-form .input-textarea {
    resize: none;
    height: 7em; }

.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none; }

.prod-bnts {
  float: right;
  width: 40%; }

.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%; }
  .prod_selector .prod_selector_wrapper {
    display: inline-block;
    position: relative; }
    .prod_selector .prod_selector_wrapper:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg); }
    .prod_selector .prod_selector_wrapper:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg); }
  .prod_selector .select_size {
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important; }
  .prod_selector .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0; }

select::-ms-expand {
  display: none; }

.prod_selector_title {
  color: #666;
  font-style: normal;
  font-size: 17px; }

.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em; }
  .prod-color-wrapper .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase; }

.responses_content .message_box {
  float: right;
  width: calc(100% - 300px);
  padding: 0 0 1em 1em;
  margin: 0 0 2em; }

.responses_content .message_top {
  border-bottom: 1px solid #3d557b;
  display: table;
  width: 100%;
  padding: 0 0 1em; }

.responses_content .message_text {
  font-size: 13px;
  margin: 0.5em 0 0; }

.responses_content .message_date,
.responses_content .message_name {
  display: table-cell; }

.responses_content .message_name {
  vertical-align: middle; }
  .responses_content .message_name > span {
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle; }

.responses_content .message_date {
  float: right;
  text-align: center; }
  .responses_content .message_date > span {
    font-size: 12px;
    padding: 1px 3px 2px; }
    .responses_content .message_date > span.dt {
      background: #07295e;
      color: #fff;
      display: block;
      border-radius: 5px; }

@media only screen and (max-width: 768px) {
  .responses_content .message_box {
    float: none;
    width: 100%; } }

.recommended-products .short_description,
.last-viewed-products .short_description {
  display: none; }

/* Раскладки каталога */
.catalog-main__item.set-2 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__item.set-2:nth-child(2) {
    margin: 0 0 14px; }

/* 3 */
.catalog-main__wrappa.set-3 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__wrappa.set-3:nth-child(2) {
    margin: 0 0 14px; }
  .catalog-main__wrappa.set-3.col-bs-2 {
    padding: 0; }
    .catalog-main__wrappa.set-3.col-bs-2 > .catalog-main__item:first-child {
      margin: 0 0 14px; }

/* 4 */
.catalog-main__wrappa.set-4 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(30% - 9px); }
  .catalog-main__wrappa.set-4.col-1 {
    padding: 0; }
    .catalog-main__wrappa.set-4.col-1 .catalog-main__title {
      top: 44%; }
  .catalog-main__wrappa.set-4.col-2 {
    padding: 0;
    width: calc(40% - 9px); }
    .catalog-main__wrappa.set-4.col-2 .catalog-main__title {
      top: 44%; }
      .catalog-main__wrappa.set-4.col-2 .catalog-main__title > h2 {
        font-size: 26px; }
    .catalog-main__wrappa.set-4.col-2 > .catalog-main__item:first-child {
      margin: 0 0 14px; }

.catalog-main__item.set-4 {
  float: left;
  margin: 0 0 1em;
  width: calc(30% - 10px); }

/* 5 */
.catalog-main__wrappa.set-5 {
  float: left;
  margin: 0 14px 14px 0;
  width: calc(50% - 7px); }
  .catalog-main__wrappa.set-5.col-1 .catalog-main__item:first-child {
    margin: 0 0 14px; }
  .catalog-main__wrappa.set-5.col-3 {
    padding: 0;
    margin: 0 0 14px; }
    .catalog-main__wrappa.set-5.col-3 > .catalog-main__item {
      margin: 0 0 14px; }
  .catalog-main__wrappa.set-5 > .row .catalog-main__item {
    margin: 0 14px 14px 0;
    width: calc(50% - 7px); }
    .catalog-main__wrappa.set-5 > .row .catalog-main__item.set-5 {
      margin: 0 0 14px; }

/* 6 */
/* 7 */
/* 8 */
/* Конец раскладки каталога */
/* Временно */
.catalog-main .catalog-main__item.set-6-more {
  float: left;
  margin: 0 2em 2em 0;
  width: calc(33.333% - 1.34em); }
  .catalog-main .catalog-main__item.set-6-more:nth-child(3n + 3) {
    margin: 0 0 2em; }

@media only screen and (max-width: 600px) {
  .catalog-main .catalog-main__wrappa.set-1, .catalog-main .catalog-main__wrappa.set-2, .catalog-main .catalog-main__wrappa.set-3, .catalog-main .catalog-main__wrappa.set-4, .catalog-main .catalog-main__wrappa.set-5, .catalog-main .catalog-main__wrappa.set-6, .catalog-main .catalog-main__wrappa.set-6-more, .catalog-main .catalog-main__wrappa.set-7, .catalog-main .catalog-main__wrappa.set-8,
  .catalog-main .catalog-main__item.set-1,
  .catalog-main .catalog-main__item.set-2,
  .catalog-main .catalog-main__item.set-3,
  .catalog-main .catalog-main__item.set-4,
  .catalog-main .catalog-main__item.set-5,
  .catalog-main .catalog-main__item.set-6,
  .catalog-main .catalog-main__item.set-6-more,
  .catalog-main .catalog-main__item.set-7,
  .catalog-main .catalog-main__item.set-8 {
    width: 100%; } }

@media only screen and (max-width: 860px) {
  .catalog-main .catalog-main__wrappa.set-1 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-2 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-3 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-4 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-5 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-6 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-6-more .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-7 .row .catalog-main__item, .catalog-main .catalog-main__wrappa.set-8 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-1 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-2 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-3 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-4 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-5 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-6 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-6-more .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-7 .row .catalog-main__item,
  .catalog-main .catalog-main__item.set-8 .row .catalog-main__item {
    width: 100%; } }

@media only screen and (max-width: 860px) {
  .catalog-main .catalog-main__wrappa.set-1 h2, .catalog-main .catalog-main__wrappa.set-2 h2, .catalog-main .catalog-main__wrappa.set-3 h2, .catalog-main .catalog-main__wrappa.set-4 h2, .catalog-main .catalog-main__wrappa.set-5 h2, .catalog-main .catalog-main__wrappa.set-6 h2, .catalog-main .catalog-main__wrappa.set-6-more h2, .catalog-main .catalog-main__wrappa.set-7 h2, .catalog-main .catalog-main__wrappa.set-8 h2,
  .catalog-main .catalog-main__item.set-1 h2,
  .catalog-main .catalog-main__item.set-2 h2,
  .catalog-main .catalog-main__item.set-3 h2,
  .catalog-main .catalog-main__item.set-4 h2,
  .catalog-main .catalog-main__item.set-5 h2,
  .catalog-main .catalog-main__item.set-6 h2,
  .catalog-main .catalog-main__item.set-6-more h2,
  .catalog-main .catalog-main__item.set-7 h2,
  .catalog-main .catalog-main__item.set-8 h2 {
    font-size: 14px;
    line-height: inherit; } }

@media only screen and (max-width: 860px) {
  .catalog-main .catalog-main__wrappa.set-1 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-2 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-3 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-4 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-5 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-6 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-6-more .catalog-main__title, .catalog-main .catalog-main__wrappa.set-7 .catalog-main__title, .catalog-main .catalog-main__wrappa.set-8 .catalog-main__title,
  .catalog-main .catalog-main__item.set-1 .catalog-main__title,
  .catalog-main .catalog-main__item.set-2 .catalog-main__title,
  .catalog-main .catalog-main__item.set-3 .catalog-main__title,
  .catalog-main .catalog-main__item.set-4 .catalog-main__title,
  .catalog-main .catalog-main__item.set-5 .catalog-main__title,
  .catalog-main .catalog-main__item.set-6 .catalog-main__title,
  .catalog-main .catalog-main__item.set-6-more .catalog-main__title,
  .catalog-main .catalog-main__item.set-7 .catalog-main__title,
  .catalog-main .catalog-main__item.set-8 .catalog-main__title {
    top: 0;
    margin: 1em; } }

@media only screen and (max-width: 860px) {
  .catalog-main .catalog-main__wrappa.set-1 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-2 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-3 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-4 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-5 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-6 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-6-more .catalog-main__image, .catalog-main .catalog-main__wrappa.set-7 .catalog-main__image, .catalog-main .catalog-main__wrappa.set-8 .catalog-main__image,
  .catalog-main .catalog-main__item.set-1 .catalog-main__image,
  .catalog-main .catalog-main__item.set-2 .catalog-main__image,
  .catalog-main .catalog-main__item.set-3 .catalog-main__image,
  .catalog-main .catalog-main__item.set-4 .catalog-main__image,
  .catalog-main .catalog-main__item.set-5 .catalog-main__image,
  .catalog-main .catalog-main__item.set-6 .catalog-main__image,
  .catalog-main .catalog-main__item.set-6-more .catalog-main__image,
  .catalog-main .catalog-main__item.set-7 .catalog-main__image,
  .catalog-main .catalog-main__item.set-8 .catalog-main__image {
    height: 100px;
    width: auto;
    margin: 0 auto; } }

/* */
.catalog-main__item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  z-index: 1; }
  .catalog-main__item:hover > .back {
    background: rgba(0, 0, 0, 0.8);
    transition: background 0.3s ease; }
  .catalog-main__item:hover .catalog-main__image {
    transform: scale(1.1);
    transition: transform 0.3s ease-out; }
  .catalog-main__item:hover .catalog-main__title > h2 {
    transition: color 0.3s ease; }
  .catalog-main__item:hover .catalog-main__title > p {
    transition: color 0.3s ease; }
  .catalog-main__item > a {
    display: block; }
  .catalog-main__item > .back {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }

.catalog-main__image {
  display: block;
  height: auto;
  max-width: 100%;
  transform-origin: center;
  transform: scale(1);
  width: 100%;
  will-change: transform; }

.catalog-main__title {
  position: absolute;
  top: 50%;
  left: 2em;
  margin: -50px 0 0 0;
  pointer-events: none;
  z-index: 5; }
  .catalog-main__title > h2 {
    color: #fff;
    font-weight: bold;
    line-height: 1;
    padding: 0 0 10px;
    text-transform: uppercase; }
  .catalog-main__title > p {
    color: #ebebeb;
    font-size: 12px;
    line-height: 1.2; }

/* Стили главного каталога */
.catalog-main {
  margin: 2em 0 0; }

/* Стили карточки в каталоге */
.catalog-item {
  padding: 0.25em 1em 7em 1em;
  position: relative;
  border-radius: 5px;
  overflow: hidden; }
  .catalog-item:hover .quick-view {
    display: block; }
  .catalog-item:hover .product_photo .show_product .photo_hover {
    opacity: 1; }
  .catalog-item .fav-link {
    position: absolute;
    top: 0;
    right: 0; }
    .catalog-item .fav-link:before {
      color: #666;
      font-size: 15px;
      margin: -11px 0 0 -7px; }
    .catalog-item .fav-link:hover:before {
      color: #07295e; }
  .catalog-item .product_photo > .show_product {
    display: block;
    position: relative; }
    .catalog-item .product_photo > .show_product > img {
      display: block;
      height: 100%;
      max-width: 100%;
      margin: 0 auto; }
    .catalog-item .product_photo > .show_product .photo_hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
  .catalog-item .catalog-item__title {
    padding: 0.5em 0;
    text-align: center; }
    .catalog-item .catalog-item__title > .show_product {
      display: block;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none; }
  .catalog-item .short_description {
    font-size: 12px;
    text-align: justify; }
    .catalog-item .short_description * {
      line-height: 1.2; }
    .catalog-item .short_description ul {
      list-style: none;
      padding: 0; }
  .catalog-item .catalog-item__bottom {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em; }
  .catalog-item .catalog-item__price-block {
    text-align: center;
    padding: 0 0 0.5em; }
  .catalog-item .prod_price_old {
    line-height: 1; }
    .catalog-item .prod_price_old > span {
      color: #8f8f8f;
      font-size: 17px;
      text-decoration: line-through; }
  .catalog-item .prod_big_price {
    line-height: 1.333; }
    .catalog-item .prod_big_price > span {
      color: #000;
      font-size: 24px; }
      .catalog-item .prod_big_price > span.currency {
        font-size: 19px; }
  .catalog-item .kolvo {
    background: #fff;
    float: none;
    margin: 0;
    padding: 3px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 1;
    cursor: default;
    border-radius: 5px; }
  .catalog-item .kolvo_buy_button_add {
    position: relative;
    text-align: center;
    margin: 0; }
    .catalog-item .kolvo_buy_button_add > .btn_add {
      float: none;
      display: inline-block;
      position: relative;
      z-index: 2; }
  @media only screen and (max-width: 1200px) {
    .catalog-item .catalog-item__title > .show_product {
      font-size: 18px; }
    .catalog-item .short_description * {
      font-size: 14px; } }
  .catalog-item .product-labels {
    right: 0; }

.desktop .catalog-item .kolvo {
  transition: all 0.3s ease;
  transform: translate3d(0, 0, 0); }

.desktop .catalog-item .kolvo_buy_button_add.hover:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 10%;
  height: 100%;
  margin: 0 0 0 -10px; }

.desktop .catalog-item .kolvo_buy_button_add.hover:hover .kolvo {
  transform: translate3d(-110%, 0, 0);
  transition: transform 0.3s ease;
  visibility: visible;
  border: 1px solid #e48a0a; }

.catalog-description__top {
  margin: 0 0 2em; }

.catalog-description__bottom {
  margin: 0 0 2em; }

/* Раскладка карточек в каталоге */
.catalog-list {
  margin: 1em 0 0; }
  .catalog-list > .catalog-item {
    float: left;
    margin: 0 0 2em;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .catalog-list > .catalog-item {
        width: calc(25% - 0.75em);
        margin: 0 1em 2em 0; } }
    @media only screen and (min-width: 992px) {
      .catalog-list > .catalog-item:nth-child(4n + 4) {
        margin: 0 0 2em; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .catalog-list > .catalog-item {
        width: calc(33.333% - 0.67em);
        margin: 0 1em 2em 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .catalog-list > .catalog-item:nth-child(3n + 3) {
        margin: 0 0 2em 0; } }
    @media only screen and (min-width: 414px) and (max-width: 767px) {
      .catalog-list > .catalog-item {
        width: calc(50% - 0.67em);
        margin: 0 1em 2em 0; } }
    @media only screen and (min-width: 414px) and (max-width: 767px) {
      .catalog-list > .catalog-item:nth-child(2n + 2) {
        margin: 0 0 2em 0; } }

.sort_panel {
  font-style: normal; }
  .sort_panel > span {
    margin: 0 0.5em 0 0;
    position: relative; }
    .sort_panel > span > a {
      margin: 0 3px 0 0; }
    .sort_panel > span.this_sort {
      font-weight: bold; }
    .sort_panel > span.desc_sort:after {
      content: "\2193"; }
    .sort_panel > span.asc_sort:after {
      content: "\2191"; }
    .sort_panel > span:first-child {
      margin: 0 0.5em; }
  @media only screen and (max-width: 768px) {
    .sort_panel {
      font-size: 14px; } }
  @media only screen and (max-width: 480px) {
    .sort_panel {
      font-size: 12px; } }

.quick-view {
  display: none;
  font-size: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 150px;
  max-height: 34px;
  text-align: center; }

section .catalog-item {
  background: #fff;
  border: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }

/* Список подразделов, плитки по 3 колонки */
.category-list .category-list__item {
  cursor: pointer;
  float: left;
  position: relative;
  margin: 0 1em 2em 0;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  @media only screen and (min-width: 992px) {
    .category-list .category-list__item {
      width: calc(25% - 0.75em);
      margin: 0 1em 2em 0; } }
  @media only screen and (min-width: 992px) {
    .category-list .category-list__item:nth-child(4n + 4) {
      margin: 0 0 2em; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .category-list .category-list__item {
      width: calc(33.333% - 0.67em);
      margin: 0 1em 2em 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .category-list .category-list__item:nth-child(3n + 3) {
      margin: 0 0 2em 0; } }
  @media only screen and (min-width: 414px) and (max-width: 767px) {
    .category-list .category-list__item {
      width: calc(50% - 0.67em);
      margin: 0 1em 2em 0; } }
  @media only screen and (min-width: 414px) and (max-width: 767px) {
    .category-list .category-list__item:nth-child(2n + 2) {
      margin: 0 0 2em 0; } }
  .category-list .category-list__item > a {
    display: block;
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid transparent; }
    @media only screen and (max-width: 1200px) {
      .category-list .category-list__item > a {
        padding: 0 1em;
        font-size: 15px; } }
    @media only screen and (max-width: 992px) {
      .category-list .category-list__item > a {
        font-size: 14px; } }
  .category-list .category-list__item .subcats_exists {
    text-align: center; }
    .category-list .category-list__item .subcats_exists a {
      display: inline-block;
      text-decoration: none;
      padding: 0 0.5em; }

.category-list .category-list__item-image {
  margin: 0 auto 1em; }
  @media only screen and (max-width: 992px) {
    .category-list .category-list__item-image {
      border-radius: 5px; } }

.category-list.hidden {
  display: none; }

section .category-list__item {
  padding: 1em 0;
  background: #fff;
  border: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }

.header-cart.open .ajaxflowcart-block {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease, opacity 0.15s ease 0.15s;
  visibility: visible; }

.cart {
  text-align: left;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .cart.empty {
    cursor: default; }
  .cart.full {
    cursor: pointer; }
    .cart.full .header_cart-goods-quantity,
    .cart.full .count-quantity,
    .cart.full .qt {
      color: #07295e; }

.desktop .cart.full:hover .cart-title {
  color: #07295e;
  transition: color 0.2s ease; }

.desktop .cart.full:hover:before {
  color: #07295e;
  transition: color 0.2s ease; }

.cart-table {
  margin: 0 0 1em;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle; }
  .cart-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .cart-table .cart-table__delete-btn:hover:before {
      color: #e48a0a;
      transition: color 0.2s ease; }
    .cart-table .cart-table__delete-btn:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #07295e;
      font-size: 21px;
      margin: -14px 0 0 -8px;
      z-index: 2; }
  .cart-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%;
    border-radius: 5px; }
  .cart-table .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%;
    border-radius: 5px;
    outline: none; }
  .cart-table > thead > tr {
    border-bottom: 1px solid #3d557b; }
    .cart-table > thead > tr > th {
      padding: 6px 0; }
      .cart-table > thead > tr > th:nth-child(1) {
        width: 13%; }
      .cart-table > thead > tr > th:nth-child(2) {
        width: 32%; }
      .cart-table > thead > tr > th:nth-child(3) {
        width: 14%; }
      .cart-table > thead > tr > th:nth-child(4) {
        width: 15%; }
      .cart-table > thead > tr > th:nth-child(5) {
        width: 16%; }
  .cart-table > tbody > tr {
    border-bottom: 1px solid #3d557b;
    border-radius: 5px; }
    .cart-table > tbody > tr > td {
      padding: 6px 5px;
      /*&.cart_product_photo {
					width: 13%;
				}
				&.cart_product_title {
					width: 32%;
				}
				&.cart_product_price {
					width: 14%;
				}
				&.cart-table__count {
					width: 15%;
				}
				&.cart_prices {
					width: 16%;
				}
				&.cart_product_delete {
					width: 10%;
					}*/ }

.cart-table__skidki {
  width: 100%; }

.cart-table__deliver {
  margin: 0 0 1em;
  width: 100%;
  text-align: center; }
  .cart-table__deliver .dostavka_radio > label {
    padding: 0;
    height: 20px;
    width: 20px;
    display: inline-block; }
  .cart-table__deliver .radio:not(checked) + label:before {
    margin-top: -5px; }
  .cart-table__deliver .radio:not(checked) + label:after {
    margin-top: -2px; }
  .cart-table__deliver .dostavka_header {
    vertical-align: top; }
  .cart-table__deliver tr {
    border-bottom: 1px solid #3d557b; }
  .cart-table__deliver td {
    padding: 3px 0; }
  .cart-table__deliver tfoot > tr {
    border-bottom: none; }
  .cart-table__deliver .cart_summary_title {
    width: 59%; }
    @media only screen and (max-width: 992px) {
      .cart-table__deliver .cart_summary_title {
        width: auto; } }
  .cart-table__deliver .cart_summary_counts {
    width: 15%; }
    @media only screen and (max-width: 992px) {
      .cart-table__deliver .cart_summary_counts {
        width: auto; } }
  .cart-table__deliver .cart_big_prices {
    width: 16%; }
    @media only screen and (max-width: 992px) {
      .cart-table__deliver .cart_big_prices {
        width: auto; } }

.order_ok_table {
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .order_ok_table th,
  .order_ok_table td {
    padding: 6px 0; }
  .order_ok_table tr {
    border-bottom: 1px solid #3d557b; }
    .order_ok_table tr:last-child {
      border-bottom: none; }

.filter_block_wrapper .filter_block {
  float: left;
  padding: 0 1em 1em;
  width: 33.333%; }
  @media only screen and (max-width: 992px) {
    .filter_block_wrapper .filter_block {
      width: 100%; } }

.filter_block_wrapper .blockReset {
  color: #07295e;
  cursor: pointer;
  float: right;
  font-size: 12px;
  text-decoration: underline; }
  .filter_block_wrapper .blockReset:hover {
    color: #e48a0a;
    text-decoration: underline;
    transition: color 0.2s ease; }

.filter_block_wrapper .filter_top {
  margin: 0 0 0.5em; }
  .filter_block_wrapper .filter_top > span {
    color: #000;
    display: block;
    font-size: 17px; }

.filter_block_wrapper .filter_mid.checkbox {
  position: static;
  visibility: visible; }

.filter_block_wrapper .filter_mid select {
  width: 100%;
  border-radius: 5px;
  outline: none; }
  .filter_block_wrapper .filter_mid select.selected {
    color: #07295e;
    border-color: #07295e; }
  .filter_block_wrapper .filter_mid select option {
    color: #000; }
    .filter_block_wrapper .filter_mid select option.selected {
      color: #07295e; }

.filter_block_wrapper .filter_mid .filter-input-wrapper {
  display: inline-block;
  margin: 0 0.5em 0 0; }
  .filter_block_wrapper .filter_mid .filter-input-wrapper label {
    background: #e48a0a;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    display: block;
    padding: 0 7px 2px; }
  .filter_block_wrapper .filter_mid .filter-input-wrapper input[type="checkbox"] {
    display: none; }
    .filter_block_wrapper .filter_mid .filter-input-wrapper input[type="checkbox"]:checked + label {
      background: #07295e; }

.filter_block_wrapper .filter_mid .input-wrapper.check {
  margin: 0 0.5em 0 0; }

.filters-left .filter_block_wrapper .filter_block {
  float: none;
  width: 100%; }

.filter_range {
  display: table;
  width: 100%; }
  .filter_range .input-wrapper {
    float: left;
    width: calc(50% - 0.5em); }
    .filter_range .input-wrapper:first-child {
      margin: 0 1em 0 0; }
    .filter_range .input-wrapper > span,
    .filter_range .input-wrapper .input-text {
      display: inline-block; }
    .filter_range .input-wrapper > span {
      font-size: 12px;
      width: 20px; }
    .filter_range .input-wrapper .input-text {
      width: calc(100% - 25px); }
  .filter_range > .col-bs-6 {
    padding: 0 0.5em 0 0; }
    .filter_range > .col-bs-6:nth-child(2) {
      padding: 0 0 0 0.5em; }
    .filter_range > .col-bs-6 > span {
      display: inline-block;
      font-size: 13px; }
  .filter_range .filter-input-wrapper {
    float: right;
    width: calc(100% - 35px); }
  .filter_range input.pole {
    width: 100%; }

.module-wrapper.filters-main .filter_block {
  padding: 1em 1em;
  min-height: 124px; }
  .module-wrapper.filters-main .filter_block:nth-child(1n + 2) {
    padding: 1em 1em 1em 0; }

.poiskat {
  padding: 5px;
  /*border-top: 1px solid $borderColor;*/
  text-align: center; }

.range_slider {
  display: block;
  padding: 10px 10px; }

.ui-slider {
  position: relative;
  height: 6px;
  border: 1px solid #777;
  background: #fff;
  margin: 0; }

.ui-slider-range {
  position: absolute;
  height: 6px;
  border: 1px solid #777;
  top: -1px;
  background: white;
  background: linear-gradient(to right, white 0%, #d8d8d8 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d8d8d8',GradientType=1 );
  /* IE6-9 */ }

.ui-slider-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  top: -2px;
  margin-left: -5px;
  border: 1px solid #000;
  border-radius: 2px;
  background: #d8d8d8;
  background: linear-gradient(to bottom, #d8d8d8 0%, white 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8d8d8', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */ }

.compareCategories {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0 0 2em; }
  .compareCategories .trashAll {
    margin: 0 5px;
    padding: 4px 10px;
    cursor: pointer;
    background: #fff;
    outline: 1px solid #aaa; }

.comparison,
.comparisonz {
  /* display: flex;*/ }

.comparisonz {
  padding: 0 0 5px;
  cursor: default;
  text-align: center; }

.comparison .owl-stage-outer .owl-stage .owl-item {
  display: inline-block;
  vertical-align: top; }

.comparison.owl-carousel .owl-nav .owl-prev,
.comparison.owl-carousel .owl-nav .owl-next {
  background: #e2e2e2;
  border-radius: 50%;
  color: #fff;
  line-height: 0.96em;
  opacity: 1;
  height: 1em;
  width: 1em;
  text-align: center; }

.comparison.owl-carousel .owl-nav .owl-prev:hover,
.comparison.owl-carousel .owl-nav .owl-next:hover {
  color: #aaa; }

.comparison.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -0.5em; }

.comparison.owl-carousel .owl-nav .owl-prev {
  left: -0.5em;
  right: auto; }

.comparison.owl-carousel .owl-nav {
  top: 50%; }

.comparison .container {
  background: #fff;
  border: 1px solid #3d557b;
  text-align: center; }

.comparison .comparisonFullItem .image-link > img {
  width: 100%; }

.comparison .comparisonFullItem:hover .removeItem {
  opacity: 1;
  transition: opacity 0.2s ease; }

.comparison .btn_add {
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0; }

.comparison .general {
  padding: 10px 0 5em;
  margin: 0 0 2px;
  position: relative; }
  .comparison .general > img {
    width: 100%; }
  .comparison .general .numbered {
    width: 30px;
    position: absolute;
    background: #07295e;
    color: #fff;
    border-radius: 0 0 26px;
    top: 0;
    left: 0;
    padding: 0 5px 5px 1px; }
  .comparison .general .image-link {
    display: block; }
  .comparison .general .removeItem {
    padding: 10px 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    opacity: 0;
    right: 0;
    height: 28px;
    width: 28px;
    text-indent: -9999px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
    .comparison .general .removeItem:before {
      content: "";
      color: #07295e;
      font-family: "FontAwesome";
      font-size: 18px;
      position: absolute;
      margin: -11px 0 0 -10px;
      top: 50%;
      left: 50%;
      color: #07295e;
      top: 50%;
      margin: -14px 0 0 -6px;
      text-indent: 0;
      z-index: 2; }
    .comparison .general .removeItem:hover:before {
      color: #e48a0a;
      transition: color 0.2s ease; }

.comparison .container > div .linkTitle {
  text-transform: uppercase;
  padding: 0 0 5px; }

.comparison .container > div .head {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  background: #f7f7f7;
  font-size: 12px;
  cursor: pointer; }

.comparison .container > div .priceOld {
  text-decoration: line-through;
  color: #aaa; }

.comparison .container > div .generalParam:nth-child(odd) {
  background: #ccc; }

.comparison .container > div .generalParam .inners {
  padding: 5px 0; }

.comparison .container > div .generalParam .inners b {
  font-size: 20px;
  line-height: 14px; }

.comparison .container > div .generalParam .param_tsvet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.comparison .container > div .generalParam .param_tsvet .colorPunkt {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  border-radius: 15px; }

.compare_box {
  display: inline-block;
  padding: 0 5px; }

.compare_box input[type=checkbox] {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle; }

.compare_text {
  cursor: pointer;
  display: inline-block;
  font-size: 12px; }
  .compare_text.in_compare {
    color: #07295e;
    text-decoration: underline; }
    .compare_text.in_compare:hover {
      color: #e48a0a;
      transition: color 0.2s ease;
      text-decoration: underline; }

.owl-placeholder {
  height: 100px;
  float: left;
  display: inline-block;
  vertical-align: top; }

.order-form {
  max-width: 300px;
  width: 100%; }
  .order-form .form_block {
    margin: 1em 0 0;
    position: relative; }
    .order-form .form_block > .form_title {
      position: static; }
  .order-form .picker-input {
    cursor: pointer; }
  .order-form .form_title {
    position: static; }
  .order-form .input-textarea,
  .order-form .input-text {
    width: 100%; }
  .order-form .input-textarea {
    height: 7em; }
  .order-form .required-message {
    font-size: 11px;
    margin: -1em 0 1em; }

.user-orders {
  margin: 0 0 1em; }

.user-orders-deliver-table {
  border-bottom: 1px solid #3d557b;
  margin: 0 0 1em;
  text-align: left;
  table-layout: fixed;
  width: 100%; }
  .user-orders-deliver-table > tbody > tr {
    border-bottom: 1px solid #3d557b; }

.user-order > .total-table,
.user-order > .summ-table {
  table-layout: fixed;
  width: 275px; }
  .user-order > .total-table td:first-child,
  .user-order > .summ-table td:first-child {
    width: 200px; }
  .user-order > .total-table td:last-child,
  .user-order > .summ-table td:last-child {
    width: 75px; }
  .user-order > .total-table .cart_prices,
  .user-order > .summ-table .cart_prices {
    text-align: right; }

.user-order > .total-table {
  margin: 0 0 1em; }

.order-table {
  margin: 1em 0;
  text-align: center;
  table-layout: fixed;
  width: 100%; }
  .order-table td {
    padding: 6px 0; }
  .order-table > thead > tr {
    border-bottom: 1px solid #3d557b; }
  .order-table > tbody > tr {
    border-bottom: 1px solid #3d557b; }

.users-orders-table {
  margin: 1em 0;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle; }
  .users-orders-table .cart-table__delete-btn {
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    padding: 0;
    position: relative; }
  .users-orders-table .cart_product_photo img {
    height: auto;
    max-width: 100px;
    width: 100%; }
  .users-orders-table .input-count {
    padding: 5px 9px 5px 1em;
    max-width: 76px;
    width: 100%; }
  .users-orders-table > thead > tr {
    border-bottom: 1px solid #3d557b; }
    .users-orders-table > thead > tr > th {
      padding: 6px 0; }
      .users-orders-table > thead > tr > th:nth-child(1) {
        width: 15%; }
      .users-orders-table > thead > tr > th:nth-child(2) {
        width: 33%; }
      .users-orders-table > thead > tr > th:nth-child(3) {
        width: 13%; }
      .users-orders-table > thead > tr > th:nth-child(4) {
        width: 16%; }
      .users-orders-table > thead > tr > th:nth-child(5) {
        width: 10%; }
      .users-orders-table > thead > tr > th:nth-child(6) {
        width: 13%; }
  .users-orders-table > tbody > tr {
    border-bottom: 1px solid #3d557b; }
    .users-orders-table > tbody > tr > td {
      padding: 8px 0; }

.rating_block {
  position: relative; }

.rating_block > div {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 5px; }

.rating_star {
  display: inline-block;
  height: 29px;
  width: 29px;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .rating_star:before {
    content: '\f005';
    font: normal 25px/1 "FontAwesome";
    color: #000;
    position: absolute;
    top: 3px;
    left: 3px;
    text-indent: 0;
    transition: color 0.2s ease; }

label.rating_star {
  cursor: pointer; }

.rating_star.half:before {
  content: '\f123';
  color: #e48a0a;
  transition: color 0.2s ease;
  z-index: 2; }

.rating_star.half:after {
  content: '\f005';
  font: normal 25px/1 "FontAwesome";
  color: #000;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1; }

.rating_star_full:before {
  content: '\f005';
  color: #e48a0a;
  transition: color 0.2s ease;
  z-index: 2; }

.rating_link {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  text-decoration: none; }

.flow_rating {
  position: absolute;
  top: -40px;
  left: 50%;
  margin-left: -78px;
  width: 140px;
  padding: 5px;
  text-align: center; }

.flow_rating > div {
  display: inline-block;
  line-height: 25px;
  margin: 0 !important; }

.rate_results {
  color: #000;
  padding: 0 1em;
  position: relative;
  min-width: 215px;
  overflow: hidden; }

.rate_results:before {
  background: #fff url(../images/fon.jpg) 0 0 fixed;
  content: 'Ваш голос учтён!';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  line-height: 24px;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out; }

.rate_results._get:before {
  top: 0;
  opacity: 1; }

.brands-description__top,
.brands-description__bottom {
  margin: 0 0 2em; }

.brand-list {
  margin: 0 0 2em; }

.brand-list__item {
  float: left;
  margin: 0 1em 2em 0;
  width: calc(20% - 0.8em); }
  .brand-list__item:hover .brand-list__item-title > a {
    color: #e48a0a; }
  .brand-list__item > a {
    display: block; }
  .brand-list__item:nth-child(5n + 5) {
    margin: 0 0 2em; }

.brand-list__item-image {
  display: block;
  max-width: 200px;
  width: 100%; }

.brand-list__item-title {
  margin: 0.5em 0 0;
  text-align: center; }
  .brand-list__item-title > a {
    font-size: 13px; }
  @media only screen and (max-width: 992px) {
    .brand-list__item-title > a {
      font-size: 12px; } }

/*@import "booking/booking";*/
.popup-block__fon {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: fixed; }

.popup-block {
  background: #fff;
  box-shadow: 7px 10px 60px 0 #383838;
  border-radius: 5px;
  display: none;
  font-size: 15px;
  width: 410px;
  margin: -100px 0 0 -205px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1010; }
  .popup-block .popup-block__content {
    margin: 1em 0 0;
    text-align: center; }
  .popup-block .input-text {
    width: 100%; }
  .popup-block .input-textarea {
    height: 8em; }
  .popup-block .popup-block__inner {
    padding: 0.5em 1em 1em;
    position: relative; }
  .popup-block .form_block.form_block__last {
    margin: 0; }
    .popup-block .form_block.form_block__last .fb_title {
      font-size: 11px; }
  .popup-block .popup-block__title {
    font-weight: 700;
    text-transform: uppercase; }
  @media only screen and (max-width: 480px) {
    .popup-block .popup-block__content {
      margin: 0.5em 0 0; }
    .popup-block .popup-block__title {
      font-size: 14px; }
    .popup-block .form_block.check-agree > label {
      font-size: 9px;
      line-height: 1.4; } }

.popup-block__cross {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 40px;
  will-change: transform;
  z-index: 2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .popup-block__cross:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: #e48a0a;
    position: relative;
    top: 3px; }
  .popup-block__cross:hover:before {
    color: #07295e;
    transition: color 0.3s ease; }

.popup-block.reg-window {
  margin: -96px 0 0 -224px;
  width: 448px; }
  .popup-block.reg-window .reg_auth-section .addition-block {
    float: right;
    margin: 8px 0 0;
    text-align: right; }
    .popup-block.reg-window .reg_auth-section .addition-block > a {
      display: block;
      font-size: 13px;
      line-height: 1.4;
      margin: 0 0.5em;
      -webkit-tap-highlight-color: transparent; }
      .popup-block.reg-window .reg_auth-section .addition-block > a > span {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .popup-block.reg-window .reg_auth-section .form_block.form_block__last {
    float: left;
    padding: 0.5em 0 0;
    width: 100px; }
    .popup-block.reg-window .reg_auth-section .form_block.form_block__last > .form_title {
      font-size: 11px;
      top: -16px; }
  @media only screen and (max-width: 480px) {
    .popup-block.reg-window {
      width: calc(320px - 2em);
      margin: -90px 0 0 calc(160px - 320px + 1em); }
      .popup-block.reg-window .popup-block__title {
        padding: 0 1.5em;
        margin: 0 0 0.5em; }
      .popup-block.reg-window .addition-block {
        float: right;
        margin: -5px 0 0;
        text-align: right;
        width: 120px; }
        .popup-block.reg-window .addition-block > a {
          font-size: 13px;
          display: inline-block; } }

.restore_pass-section {
  background: #fff;
  display: none;
  height: auto;
  padding: 0.5em 1em 1em; }
  .restore_pass-section .form_block.key-block .input-text {
    float: left;
    width: calc(100% - 1em - 92px); }
  .restore_pass-section .form_block.key-block .key-img {
    position: absolute;
    top: -10px;
    right: 0;
    height: 34px;
    width: 92px; }
  .restore_pass-section .form_block.key-block .help-block {
    right: 11em; }
  .restore_pass-section .form_block.key-title {
    margin: 0.5em 0 0; }
    .restore_pass-section .form_block.key-title .form_title {
      font-size: 11px;
      position: static; }
  .restore_pass-section .form_block.form_block__last .button {
    margin: 1em 0 0; }

.popup-block.auth-ok {
  margin-top: -3em; }

.popup-block.auth-error {
  margin-top: -3em; }

.popup-block.order-call {
  margin-top: -172px; }
  @media only screen and (max-width: 480px) {
    .popup-block.order-call {
      max-width: calc(320px - 2em);
      margin: -12em 0 0 calc(160px - 320px + 1em);
      width: 100%; } }

.popup-block.sendformok {
  margin-top: -3em; }

.popup-block.callbackformok {
  margin-top: -3em; }

.popup-block.add-to-cart {
  margin: -46px 0 0 -202px; }
  .popup-block.add-to-cart .link-to-cart {
    position: relative; }
  @media only screen and (max-width: 480px) {
    .popup-block.add-to-cart {
      margin: -42px 0 0 -145px;
      max-width: 290px;
      width: 290px; } }

.popup-block.compare-popup {
  top: 50%;
  left: 50%;
  height: 800px;
  margin: -400px 0 0 calc(-600px + 1em);
  width: calc(1200px - 2em); }
  @media only screen and (max-width: 1200px) {
    .popup-block.compare-popup {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto; } }

.popup-block.compare-popup-error {
  width: 404px;
  margin: -39px 0 0 -202px; }

.product-popup {
  height: 100vh;
  left: 0;
  outline: medium none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  vertical-align: middle;
  width: 100vw;
  z-index: 1009;
  -webkit-overflow-scrolling: touch; }

.product-popup__window {
  position: relative;
  left: 0;
  right: 0;
  top: 1em;
  bottom: 1em;
  margin: auto;
  background: #fff;
  border: 1px solid #ccc;
  max-width: 932px;
  padding: 1em;
  width: 100%;
  z-index: 901; }
  @media only screen and (max-width: 768px) {
    .product-popup__window {
      top: 0;
      bottom: 0; } }
  .product-popup__window .catalog-item .quick-view {
    display: none; }

.product-popup__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 6px 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .product-popup__close:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: #e48a0a;
    position: relative;
    top: 0; }
  .product-popup__close:hover:before {
    color: #07295e;
    transition: color 0.3s ease; }

#layerok {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, 0.3); }

@media only screen and (max-width: 480px) {
  .g-recaptcha {
    transform: scale(0.8);
    margin: 0 0 0 -20px; } }

.g-recaptcha > div {
  margin: 0 auto; }

.g-recaptcha-bubble-arrow + div {
  width: 100% !important;
  left: -25%; }

.vote-block {
  margin: 0 auto; }
  .vote-block .flag {
    position: relative; }
    .vote-block .flag:before {
      content: '\f00c';
      color: #000;
      font: normal 14px/1 "FontAwesome";
      margin: 0 0 0 5px; }
  .vote-block > .vote-block__box .vote-block__box-innr {
    list-style: none;
    margin: 0 0 1em; }
    .vote-block > .vote-block__box .vote-block__box-innr > li {
      margin: 0 0 3px; }
      .vote-block > .vote-block__box .vote-block__box-innr > li > label {
        color: #000;
        display: block;
        font-size: 13px;
        height: 20px;
        position: relative;
        padding: 0 0 0 2em; }
  .vote-block > .vote-block__box .opr_btn {
    text-align: center; }
  .vote-block > .vote-block__box .vote-block__otvet-var {
    color: #000;
    font-size: 13px;
    position: relative;
    line-height: 13px;
    padding: 5px 70px 5px 0; }
  .vote-block > .vote-block__box .vote-block__otvet_-var-percent {
    color: #07295e;
    font-size: 12px;
    position: absolute;
    right: 5px;
    bottom: -16px; }
  .vote-block > .vote-block__box .vote-block__line {
    height: 16px;
    background: #fff;
    margin: 0 0 5px;
    border-radius: 5px;
    overflow: hidden; }
  .vote-block > .vote-block__box .vote-block__line-in {
    height: 16px;
    background: #e48a0a;
    width: 0;
    will-change: width;
    transition: all .5s ease-in; }
  .vote-block > .vote-block__box .vote-block__total {
    font-size: 12px;
    font-weight: 700;
    padding-top: 10px; }
  .vote-block > .vote-block__box .vote-block__total-percent {
    bottom: 5px;
    color: #07295e; }
  .vote-block .vote-block__title {
    color: #000;
    font-size: 15px;
    margin: 0 0 1em;
    text-align: center; }

@media only screen and (max-width: 992px) {
  .vote-block > .vote-block__box {
    padding: 1em 0 1em 1em; } }

.search-form {
  position: relative;
  margin: 7px 0;
  width: 100%; }
  @media only screen and (max-width: 992px) {
    .search-form {
      max-width: 100%; } }
  .search-form .input-text {
    width: 100%;
    padding: 5px 2.5em 5px 0.5em; }

.search-form__button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  background: #3d557b;
  border-radius: 3px;
  margin: 2px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15); }
  .search-form__button:before {
    content: "";
    color: #07295e;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    font-size: 16px;
    margin-left: -7px;
    margin-top: -13px;
    z-index: 2; }
  .search-form__button:not(.disabled) {
    background: linear-gradient(to bottom, #f0df53, #ff7402); }
  .search-form__button.disabled {
    cursor: default; }
    .search-form__button.disabled:before {
      color: #fff; }

.search-close__button {
  display: none; }

.mobile .search-close__button {
  display: block;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 0;
  left: 0; }
  .mobile .search-close__button:before {
    content: '\f00d';
    font: normal 21px/1 "FontAwesome";
    color: #222;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11px 0 0 -8px; }

.search_box {
  border-bottom: 1px solid #3d557b;
  padding: 1em 0;
  margin-bottom: 1em; }
  .search_box > h3 > a {
    color: #07295e;
    font-size: 21px;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none; }
    .search_box > h3 > a:hover {
      color: #e48a0a; }
  .search_box .search-photo {
    float: left;
    margin: 0 1em 0.5em 0; }
    .search_box .search-photo > a {
      display: block; }
      .search_box .search-photo > a > img {
        max-width: 220px; }
  .search_box .price-wrapper {
    margin: 1em 0 0; }
  .search_box .kolvo_buy_button_add {
    float: left;
    margin: 0; }
  .search_box .search-price {
    float: left;
    margin-right: 10px; }
    .search_box .search-price .price-title {
      font-size: 15px;
      font-weight: bold; }
    .search_box .search-price .price {
      font-size: 24px;
      font-weight: bold; }
    .search_box .search-price .currency {
      font-size: 16px;
      font-weight: bold; }

.main_src_text {
  min-height: 95px; }

.search_box .buy_link {
  float: none;
  display: inline-block; }

.content_search img {
  margin: 0 10px 10px 0; }

.content_search h3 {
  margin: 0;
  font-size: 14px; }

.content_search div.search_box {
  margin: 0 0 15px; }

.content_search cite {
  margin: 2px 0;
  font-style: normal;
  color: #ca0d1f; }

.content_search p {
  margin: 0; }

.search_result {
  position: absolute;
  top: 30px;
  left: 0;
  margin: 4px 0 0;
  background: #fff;
  border-radius: 5px;
  width: 300px;
  font-size: 11px;
  line-height: 16px;
  z-index: 999;
  text-align: left; }

.search_result > div {
  box-shadow: 1px 1px 5px #666;
  border: 1px solid #C6C6C6; }

.search_result > div > .search_box {
  padding: 5px 5px;
  border-top: 1px solid #C6C6C6;
  cursor: pointer; }

.search_result > div > .search_box p {
  margin: 0; }

.search_result > div > .search_box:hover {
  background: #eee; }

.search_result > div > .search_box:first-child {
  padding: 2px 5px;
  border-top: none; }

.search_result > div > .search_box img {
  margin: 0 10px 0; }

span.search_sell {
  color: #000;
  background: #ffff00; }

.ui-autocomplete-category {
  font-weight: bold;
  padding: 0.2em 0.4em;
  margin: 0.8em 0 0.2em;
  line-height: 1.5;
  font-size: 16px; }

.ui-autocomplete-category:first-child {
  padding: 0 0.4em !important;
  margin: 0 0 0.2em !important; }

.ui-state-focus {
  background: #eee !important;
  border: none !important; }

.ui-autocomplete-category {
  border-bottom: 1px solid #ccc; }

.ui-menu-item {
  margin: 0 !important;
  padding: .4em 1em !important;
  width: auto !important; }

.ui-autocomplete {
  z-index: 999 !important; }

.top-menu_search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px; }

.img_search .search_form > .search_input {
  padding-right: 45px; }

.img_search .search_form > .search_button {
  width: 45px;
  height: 55px;
  background: url("../images/img_search.png") 50% -6px no-repeat;
  color: #fff; }

.photos .photo-item a img {
  width: 100%; }

.photos-slider .photo-item > a {
  display: block; }

.albums_list,
.photos_list {
  text-align: center;
  margin: 0px -5px 0; }

.album_block,
.photo_block {
  display: inline-block;
  text-align: center;
  margin: 0 5px 10px;
  vertical-align: top; }

.photo_block {
  max-width: 175px; }

.album_block {
  max-width: 221px; }

.album_photo img,
.photo_photo img {
  display: block; }

.album_title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 5px 0; }

.photo_description {
  font-size: 13px;
  line-height: 15px;
  padding: 5px 0; }

.gallery-photo {
  margin: 1em 0 0;
  padding: 0 2em; }
  .gallery-photo .owl-nav.disabled {
    display: block; }
  .gallery-photo .owl-nav .owl-next {
    right: -0.75em; }
  .gallery-photo .owl-nav .owl-prev {
    left: -0.75em; }
  .gallery-photo .owl-nav .owl-prev,
  .gallery-photo .owl-nav .owl-next {
    color: #30a12a;
    font-size: 3em;
    font-weight: bold;
    opacity: 0.5; }
    .gallery-photo .owl-nav .owl-prev:hover,
    .gallery-photo .owl-nav .owl-next:hover {
      opacity: 1;
      transition: opacity 0.2s ease; }
  .gallery-photo .photo-item > a > img {
    display: block;
    height: auto;
    max-width: 240px;
    width: 100%; }
  @media only screen and (max-width: 429px) {
    .gallery-photo {
      padding: 0 1em; } }
  @media only screen and (max-width: 367px) {
    .gallery-photo {
      padding: 0 0.5em; } }

.photo_block,
.album-block {
  float: left;
  margin: 0 1em 1em 0;
  width: calc(25% - 0.75em); }
  .photo_block:nth-child(4n + 4),
  .album-block:nth-child(4n + 4) {
    margin: 0 0 1em; }
  .photo_block .photo_photo > a > img,
  .photo_block .album-photo > a > img,
  .album-block .photo_photo > a > img,
  .album-block .album-photo > a > img {
    display: block;
    height: auto;
    max-width: 360px;
    width: 100%; }
  .photo_block .album-title,
  .album-block .album-title {
    padding: 0 0.5em;
    margin: 0.5em 0 0;
    text-align: center; }
    .photo_block .album-title > a,
    .album-block .album-title > a {
      font-size: 14px;
      margin: 0.5em 0; }
  @media only screen and (max-width: 992px) {
    .photo_block,
    .album-block {
      width: calc(50% - 0.5em); }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 0 1em; } }
  @media only screen and (max-width: 768px) {
    .photo_block,
    .album-block {
      width: calc(25% - 0.75em); }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 0 1em 0; } }
  @media only screen and (max-width: 550px) {
    .photo_block,
    .album-block {
      width: calc(50% - 0.5em); }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 1em 1em 0; }
      .photo_block:nth-child(2n + 2),
      .album-block:nth-child(2n + 2) {
        margin: 0 0 1em; } }
  @media only screen and (max-width: 380px) {
    .photo_block,
    .album-block {
      margin: 0 0 1em;
      width: 100%; }
      .photo_block:nth-child(4n + 4),
      .album-block:nth-child(4n + 4) {
        margin: 0 0 1em; } }

.photos_text {
  font-size: 14px; }

.prev__photo2, .next__photo2, .prev__photo2:active, .next__photo2:active {
  background: linear-gradient(to bottom, #ada4ff, #ada4ff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ada4ff, endColorstr=#ada4ff, GradientType=0);
  background-color: #ada4ff; }

/*@import "qa";*/
/*@import "messages";*/
.module {
  margin: 1em 0 2em;
  border-radius: 5px;
  overflow: hidden;
  background: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%); }
  .module .module-header {
    padding: .3em .5em;
    margin: 0 0 1px;
    box-shadow: inset 0 1px 0px 0 #fff;
    border: 1px solid #3c557b;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(to bottom, #3c557b, #213b63);
    text-align: center; }
    .module .module-header > span {
      color: #fff;
      cursor: default;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase; }
  .module .filter-wrapper,
  .module .vote-block {
    padding: 1em; }
  .module .news-block {
    padding: 1em 1em 0; }

/* Оформление модулей в контент области */
.module-inner {
  clear: both;
  margin: 1em 0 2em; }
  .module-inner > .module-header {
    background: none;
    padding: .3em 0 0 .5em;
    margin: 0 0 1.5em;
    text-align: right;
    border-bottom: 1px solid #3c557b; }
    .module-inner > .module-header > span,
    .module-inner > .module-header > h2 {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: .3em .5em;
      background: linear-gradient(to bottom, #3c557b, #213b63);
      box-shadow: inset 0 1px 0px 0 #fff;
      border: 1px solid #3c557b;
      border-radius: 5px 5px 0 0;
      min-width: 242px;
      text-align: center; }

.module-wrapper {
  position: relative; }

/* Бренды */
.module.brands .brands-list {
  display: block;
  list-style: none;
  padding: 1em; }

.module.brands .brands-list__item {
  font-size: 1.14286em;
  line-height: 1.9; }
  .module.brands .brands-list__item > span {
    color: #e48a0a; }

.module.brands .brands-list__link {
  color: #07295e;
  transition: color 0.3s ease; }
  .module.brands .brands-list__link:hover {
    color: #e48a0a; }

@media only screen and (max-width: 768px) {
  .module.brands {
    float: left;
    padding: 0 0.5em 0 1em;
    width: 50%; } }

@media only screen and (max-width: 480px) {
  .module.brands {
    float: none;
    padding: 0;
    width: 100%; } }

.mobile section .module-inner,
.tablet section .module-inner {
  padding: 1em 0; }

.mobile .module > .module-header.toggle,
.mobile .module-inner > .module-header.toggle,
.tablet .module > .module-header.toggle,
.tablet .module-inner > .module-header.toggle {
  position: relative; }
  .mobile .module > .module-header.toggle:before,
  .mobile .module-inner > .module-header.toggle:before,
  .tablet .module > .module-header.toggle:before,
  .tablet .module-inner > .module-header.toggle:before {
    content: "";
    color: #07295e;
    font-family: "FontAwesome";
    font-size: 18px;
    position: absolute;
    margin: -11px 0 0 -10px;
    top: 50%;
    left: 50%;
    font-size: 14px;
    color: #fff;
    left: inherit;
    right: 1em;
    margin: -10px 0 0;
    z-index: 2; }
  .mobile .module > .module-header.toggle.active:before,
  .mobile .module-inner > .module-header.toggle.active:before,
  .tablet .module > .module-header.toggle.active:before,
  .tablet .module-inner > .module-header.toggle.active:before {
    content: '\f077'; }

.mobile .module > .module-header > span,
.mobile .module > .module-header > h2,
.mobile .module-inner > .module-header > span,
.mobile .module-inner > .module-header > h2,
.tablet .module > .module-header > span,
.tablet .module > .module-header > h2,
.tablet .module-inner > .module-header > span,
.tablet .module-inner > .module-header > h2 {
  color: #fff; }
  .mobile .module > .module-header > span > a,
  .mobile .module > .module-header > h2 > a,
  .mobile .module-inner > .module-header > span > a,
  .mobile .module-inner > .module-header > h2 > a,
  .tablet .module > .module-header > span > a,
  .tablet .module > .module-header > h2 > a,
  .tablet .module-inner > .module-header > span > a,
  .tablet .module-inner > .module-header > h2 > a {
    text-decoration: none;
    color: #fff; }

.mobile .module .module-wrapper,
.mobile .module-inner .module-wrapper,
.tablet .module .module-wrapper,
.tablet .module-inner .module-wrapper {
  padding: 0;
  margin: 0 0 1em; }

.mobile .module .module-header,
.mobile .module-inner .module-header,
.tablet .module .module-header,
.tablet .module-inner .module-header {
  text-align: center; }

.mobile .main-left-menu,
.tablet .main-left-menu {
  margin: 0 0 1em; }
  .mobile .main-left-menu .module-header,
  .tablet .main-left-menu .module-header {
    margin: 0; }
  @media only screen and (max-width: 768px) {
    .mobile .main-left-menu .module-wrapper,
    .tablet .main-left-menu .module-wrapper {
      display: none; } }

.news-block__title > a {
  text-transform: uppercase;
  text-decoration: none; }
  .news-block__title > a:hover {
    text-decoration: none; }

.news-block__text {
  font-size: 14px;
  margin: 0.5em 0;
  line-height: 1.333;
  text-align: justify; }

.news-block__date {
  vertical-align: middle;
  margin: 0 0 0.5em; }
  .news-block__date > span {
    background: #07295e;
    color: #fff;
    font-size: 13px;
    padding: 0 8px 1px;
    white-space: nowrap;
    border-radius: 5px; }
    .news-block__date > span.news-publish {
      background: none;
      display: none;
      color: #000;
      font-size: 10px;
      padding: 0; }

/* Новости на главной     */
/* ...в левой колонке     */
.news-block .news-block__item {
  margin: 0 0 1em;
  padding: 0 0 1em;
  border-bottom: 1px solid #07295e; }

.news-block .news-block__header {
  display: table;
  width: 100%; }

.news-block .news-block__image {
  border-radius: 5px; }

.news-block .readmore-link {
  display: block; }

.news-block .news-all,
.news-block .news-archive {
  padding: 0 0 0.5em;
  text-align: center; }
  .news-block .news-all > a,
  .news-block .news-archive > a {
    text-transform: uppercase; }

@media only screen and (max-width: 600px) {
  .news-block .news {
    width: calc(50% - 1em); }
    .news-block .news:last-child {
      width: 100%; } }

@media only screen and (max-width: 480px) {
  .news-block .news {
    width: 100%; } }

/* Новости на главной     */
/* ... в контент области  */
.module-inner .news-block .news-block__item {
  float: left;
  margin: 0 2em 1em 0;
  width: calc(33.333% - 1.34em); }
  .module-inner .news-block .news-block__item:nth-child(3) {
    margin: 0 0 1em; }
  @media only screen and (max-width: 768px) {
    .module-inner .news-block .news-block__item {
      width: 100%; } }

/* Список новостей - news/page/1 */
.news-list .readmore-link {
  display: block;
  margin: 1em 0 0; }

.news-list .news-block__image {
  float: left;
  margin: 0 1em 0.5em 0;
  max-width: 200px;
  border-radius: 5px; }

.news-list .news-block__item {
  border-bottom: 1px solid #3d557b;
  margin: 0 0 2em;
  padding: 0 0 1em; }
  .news-list .news-block__item:last-child {
    border-bottom: none; }

.news-list .news-block__header {
  display: table;
  width: 100%; }
  .news-list .news-block__header .news-block__date {
    text-align: right; }
  .news-list .news-block__header .news-block__date,
  .news-list .news-block__header .news-block__title {
    display: table-cell; }

section .news-block .news-block__item {
  padding: 1em;
  background: #fff;
  border: 1px solid url(../images/footer-fon.jpg) 0 0-10%; }

.news-detail {
  margin: 1em 0 0; }
  .news-detail .news-detail__header {
    display: table;
    width: 100%; }
  .news-detail .news-detail__title {
    font-size: 18px;
    display: table-cell; }
  .news-detail .news-detail__date {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 85px;
    padding-bottom: 4px; }
    .news-detail .news-detail__date > span {
      background: #07295e;
      color: #fff;
      font-size: 13px;
      padding: 0 8px 1px;
      white-space: nowrap;
      border-radius: 5px; }
      .news-detail .news-detail__date > span.pbl {
        background: none;
        display: block;
        color: #000;
        font-size: 10px;
        padding: 0; }
  .news-detail .news-detail__text > a {
    float: left;
    outline: none; }
  .news-detail .news-detail__image {
    display: block;
    margin: 0 1em 0 0;
    border-radius: 5px; }

.news_navigation {
  display: table;
  margin: 1em 0 0;
  width: 100%; }
  .news_navigation > .prev_news {
    text-align: left; }
  .news_navigation > .next_news {
    text-align: right; }
  .news_navigation > .all_news {
    text-align: center; }
  .news_navigation > .all_news,
  .news_navigation > .next_news,
  .news_navigation > .prev_news {
    display: table-cell;
    font-size: 13px;
    width: 33.333%; }
    @media only screen and (max-width: 768px) {
      .news_navigation > .all_news,
      .news_navigation > .next_news,
      .news_navigation > .prev_news {
        display: block;
        width: 100%; } }
    .news_navigation > .all_news > a,
    .news_navigation > .next_news > a,
    .news_navigation > .prev_news > a {
      text-decoration: underline; }
      .news_navigation > .all_news > a:hover,
      .news_navigation > .next_news > a:hover,
      .news_navigation > .prev_news > a:hover {
        text-decoration: underline; }

.reg_reg-section {
  margin: 2em 0 0; }
  .reg_reg-section table {
    table-layout: fixed;
    max-width: 350px;
    width: 100%; }
  .reg_reg-section .input-text {
    width: 100%; }
  .reg_reg-section .reg_col_1 {
    font-size: 13px;
    padding: 0 1em 0 0;
    width: 138px; }
  .reg_reg-section .reg_col_2 {
    padding: 0 0 0.5em;
    position: relative; }
  .reg_reg-section .button {
    margin: 1em 0 0; }
  .reg_reg-section .last {
    text-align: center; }
    .reg_reg-section .last .button {
      display: inline-block; }

.profile-wrapper {
  background: #fff;
  min-width: 260px;
  max-width: 292px;
  padding: 1em; }
  .profile-wrapper .reg_col_1,
  .profile-wrapper .reg_col_2 {
    display: block; }
  .profile-wrapper .reg_col_1 {
    color: #000;
    font-size: 12px;
    padding: 0 0 0.25em; }
  .profile-wrapper .reg_col_2 {
    padding: 0 0 0.25em;
    position: relative; }
    .profile-wrapper .reg_col_2.last {
      padding: 0;
      margin: 1em 0 0; }
    .profile-wrapper .reg_col_2 .input-text {
      width: 100%; }

.changepass-block > form > table,
.profile-block > form > table {
  table-layout: fixed;
  width: 100%; }

.changepass-block .changepass-block__title {
  margin: 1em 0 0.5em;
  text-align: center; }

@media only screen and (max-width: 480px) {
  .profile-block {
    margin: 1em 0 0; }
    .profile-block .profile-table {
      width: 100%; }
      .profile-block .profile-table tr {
        display: block; }
        .profile-block .profile-table tr:last-child .reg_col_1 {
          line-height: 0; }
    .profile-block .input-text {
      width: 100%; }
    .profile-block .reg_col_1,
    .profile-block .reg_col_2 {
      display: block; }
  .pass-change-table {
    table-layout: fixed;
    width: 100%; }
    .pass-change-table tr {
      display: block; }
      .pass-change-table tr:last-child .reg_col_1 {
        line-height: 0; }
    .pass-change-table .input-text {
      width: 100%; }
    .pass-change-table .reg_col_1,
    .pass-change-table .reg_col_2 {
      display: block; }
  .reg_col_1 {
    font-size: 14px; } }

.orders_btnm {
  margin: 1em 0 2em; }

.fancybox-enabled {
  overflow: hidden; }
  .fancybox-enabled body {
    overflow: visible;
    height: 100%; }

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  backface-visibility: hidden; }

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992; }

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  backface-visibility: hidden; }

.fancybox-container--ready .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.2, 0.61, 0.36, 1); }

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr; }

.fancybox-show-controls .fancybox-controls {
  opacity: 1; }

.fancybox-infobar {
  display: none; }

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
  margin: 3em 0 0; }

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased; }

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all; }
  .fancybox-buttons .popup-block__cross {
    position: static;
    height: 44px;
    width: 44px; }
    .fancybox-buttons .popup-block__cross:hover:before {
      color: #07295e;
      transition: color 0.2s ease; }
    .fancybox-buttons .popup-block__cross:before {
      font-size: 22px;
      top: 10px; }

.fancybox-show-buttons .fancybox-buttons {
  display: block; }

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr; }

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent; }

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch; }
  .fancybox-slide:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0; }
  .fancybox-slide > * {
    display: inline-block;
    position: relative;
    padding: 24px;
    margin: 44px 0 44px;
    border-width: 0;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    overflow: auto; }

.fancybox-slide--image {
  overflow: hidden; }
  .fancybox-slide--image:before {
    display: none; }

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: "Arial", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch; }

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent; }

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent no-repeat;
  cursor: default;
  overflow: visible;
  transform-origin: top left;
  background-size: 100% 100%;
  backface-visibility: hidden; }

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%; }

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: zoom-out; }

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: zoom-in; }

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: grab; }

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: grabbing; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden; }

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default; }

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Arial", Helvetica, Arial, sans-serif; }

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer; }
  .fancybox-close-small:after {
    content: '×';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    font: 20px/30px Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
    border-width: 0;
    background: #fff;
    transition: background .2s;
    z-index: 2; }
  .fancybox-close-small:focus:after {
    outline: 1px dotted #888; }
  .fancybox-close-small:hover:after {
    color: #000;
    background: #fff url(../images/fon.jpg) 0 0 fixed; }

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent; }

/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  z-index: 99998;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none; }

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1; }

.fancybox-caption {
  padding: 1em 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 1.5em;
  font-family: "Arial", Helvetica, Arial, sans-serif;
  color: #fff;
  line-height: 1.333;
  text-align: center;
  -webkit-text-size-adjust: none; }
  .fancybox-caption a,
  .fancybox-caption button,
  .fancybox-caption .popup-block.add-to-cart .link-to-cart, .popup-block.add-to-cart
  .fancybox-caption .link-to-cart {
    pointer-events: all; }
  .fancybox-caption a {
    color: #fff;
    text-decoration: underline; }

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  vertical-align: top;
  outline: none;
  box-shadow: none; }
  .fancybox-button:hover {
    background: transparent; }
    .fancybox-button:hover:before {
      color: #07295e;
      transition: color 0.2s ease; }
  .fancybox-button:before {
    content: '';
    pointer-events: none;
    position: absolute;
    display: block; }

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none; }
  .fancybox-button--disabled:before, .fancybox-button--disabled:after {
    color: #000; }

.fancybox-button--left {
  border-bottom-left-radius: 5px; }
  .fancybox-button--left:after {
    left: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(-135deg); }

.fancybox-button--right {
  border-bottom-right-radius: 5px; }
  .fancybox-button--right:after {
    right: 20px;
    top: 18px;
    width: 6px;
    height: 6px;
    background: transparent;
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
    transform: rotate(45deg); }

.fancybox-button--close {
  float: right; }
  .fancybox-button--close:before {
    content: '×';
    top: -3px;
    left: 8px;
    font-size: 35px;
    color: #dda209; }

/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left; }
  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close),
  .fancybox-buttons .popup-block.add-to-cart .link-to-cart:not(.fancybox-button--close), .popup-block.add-to-cart
  .fancybox-buttons .link-to-cart:not(.fancybox-button--close) {
    display: none !important; }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0; } }

/* Fullscreen  */
.fancybox-button--fullscreen:before {
  width: 18px;
  height: 13px;
  left: 11px;
  top: 13px;
  border: 2px solid #e48a0a;
  background: none;
  border-radius: 5px; }

.fancybox-button--fullscreen:hover:before {
  color: transparent;
  border-color: #07295e; }

/* Slideshow button */
.fancybox-button--play:before {
  background: none;
  content: '\f04b';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 16px; }

.fancybox-button--pause:before {
  background: none;
  content: '\f04c';
  color: #dda209;
  font: normal 20px/1 "FontAwesome";
  position: absolute;
  top: 12px;
  left: 13px; }

/* Thumbs */
.fancybox-button--thumbs span {
  font-size: 23px; }

.fancybox-button--thumbs:before {
  background: none;
  content: '\f00a';
  color: #dda209;
  font: normal 22px/1 "FontAwesome";
  position: absolute;
  top: 11px;
  left: 11px; }

.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px; }

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent; }
  .fancybox-thumbs > ul {
    list-style: none;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0; }
    .fancybox-thumbs > ul > li {
      float: left;
      overflow: hidden;
      max-width: 50%;
      padding: 0;
      margin: 0;
      width: 105px;
      height: 75px;
      position: relative;
      cursor: pointer;
      outline: none;
      border: 5px solid #fff;
      border-top-width: 0;
      border-right-width: 0;
      -webkit-tap-highlight-color: transparent;
      backface-visibility: hidden; }
      .fancybox-thumbs > ul > li.fancybox-thumbs-loading {
        background: rgba(0, 0, 0, 0.1); }
      .fancybox-thumbs > ul > li > img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        max-height: none;
        -webkit-touch-callout: none;
        user-select: none; }
      .fancybox-thumbs > ul > li:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        border: 4px solid #07295e;
        z-index: 99991;
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
      .fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
        opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important; }
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0; } }

.podpiska_form .input-text {
  margin: 0 auto; }

.podpiska_form .form_block + div {
  text-align: center;
  padding: 1em 0; }

/*ANIMATE*/
.animated, header, header.scrolled, .catalog-item:hover .product_photo .show_product .photo_hover {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animate {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite, header.infinite, .catalog-item:hover .product_photo .show_product .infinite.photo_hover {
  animation-iteration-count: infinite; }

.animated.hinge, header.hinge, .catalog-item:hover .product_photo .show_product .hinge.photo_hover {
  animation-duration: 2s; }

.animated.flipOutX, header.flipOutX, .catalog-item:hover .product_photo .show_product .flipOutX.photo_hover,
.animated.flipOutY,
header.flipOutY,
.catalog-item:hover .product_photo .show_product .flipOutY.photo_hover,
.animated.bounceIn,
header.bounceIn,
.catalog-item:hover .product_photo .show_product .bounceIn.photo_hover,
.animated.bounceOut,
header.bounceOut,
.catalog-item:hover .product_photo .show_product .bounceOut.photo_hover {
  animation-duration: .75s; }

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

.bounce {
  animation-name: bounce;
  transform-origin: center bottom; }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.05, 1.05, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-name: pulse; }

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  animation-name: rubberBand; }

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0); } }

.shake {
  animation-name: shake; }

@keyframes headShake {
  0% {
    transform: translateX(0); }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    transform: translateX(2px) rotateY(3deg); }
  50% {
    transform: translateX(0); } }

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake; }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  from {
    transform: none; }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    transform: none; } }

.wobble {
  animation-name: wobble; }

@keyframes jello {
  from, 11.1%, to {
    transform: none; }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    transform: skewX(0.39063deg) skewY(0.39063deg); }
  88.8% {
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  animation-name: jello;
  transform-origin: center; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  animation-name: bounceIn; }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

.bounceInDown {
  animation-name: bounceInDown; }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounceInLeft {
  animation-name: bounceInLeft; }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounceInRight {
  animation-name: bounceInRight; }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  animation-name: bounceOut; }

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  animation-name: bounceOutDown; }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  animation-name: bounceOutLeft; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  animation-name: bounceOutRight; }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  animation-name: bounceOutUp; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDown, header.scrolled {
  animation-name: fadeInDown; }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDownBig {
  animation-name: fadeInDownBig; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRightBig {
  animation-name: fadeInRightBig; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUpBig {
  animation-name: fadeInUpBig; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  animation-name: fadeOutDownBig; }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig; }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  animation-name: fadeOutRight; }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  animation-name: fadeOutRightBig; }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  animation-name: fadeOutUpBig; }

@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  to {
    transform: perspective(400px);
    animation-timing-function: ease-in; } }

.animated.flip, header.flip, .catalog-item:hover .product_photo .show_product .flip.photo_hover {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip; }

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX; }

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInY, .catalog-item:hover .product_photo .show_product .photo_hover {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInY; }

@keyframes flipOutX {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@keyframes flipOutY {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out; }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in; }

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1; } }

.rotateIn {
  animation-name: rotateIn; }

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  animation-name: rotateInDownLeft; }

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  animation-name: rotateInDownRight; }

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  animation-name: rotateInUpLeft; }

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  animation-name: rotateInUpRight; }

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1; }
  to {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  animation-name: rotateOut; }

@keyframes rotateOutDownLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft; }

@keyframes rotateOutDownRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  animation-name: rotateOutDownRight; }

@keyframes rotateOutUpLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft; }

@keyframes rotateOutUpRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  animation-name: rotateOutUpRight; }

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  animation-name: hinge; }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom; }
  50% {
    transform: rotate(-10deg); }
  70% {
    transform: rotate(3deg); }
  to {
    opacity: 1;
    transform: scale(1); } }

.jackInTheBox {
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    transform: none; } }

.rollIn {
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  animation-name: rollOut; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  animation-name: zoomInDown; }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  animation-name: zoomInLeft; }

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  animation-name: zoomInRight; }

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  animation-name: zoomInUp; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  animation-name: zoomOutDown; }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center; } }

.zoomOutLeft {
  animation-name: zoomOutLeft; }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center; } }

.zoomOutRight {
  animation-name: zoomOutRight; }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  animation-name: zoomOutUp; }

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  animation-name: slideInDown; }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  animation-name: slideInRight; }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  animation-name: slideInUp; }

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  animation-name: slideOutDown; }

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  animation-name: slideOutRight; }

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  animation-name: slideOutUp; }

.no-js .wow {
  visibility: visible; }

/*BOOTSTRAP*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/*@import "reboot";*/
/*@import "type";*/
/*@import "images";*/
/*@import "code";*/
.container-bootstrap {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  /*@include make-container-max-widths();*/
  max-width: 1200px; }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/*@import "tables";*/
/*@import "forms";*/
/*@import "buttons";*/
/*@import "transitions";*/
/*@import "dropdown";*/
/*@import "button-group";*/
/*@import "input-group";*/
/*@import "custom-forms";*/
/*@import "nav";*/
/*@import "navbar";*/
/*@import "card";*/
/*@import "breadcrumb";*/
/*@import "pagination";*/
/*@import "badge";*/
/*@import "jumbotron";*/
/*@import "alert";*/
/*@import "progress";*/
/*@import "media";*/
/*@import "list-group";*/
/*@import "close";*/
/*@import "modal";*/
/*@import "tooltip";*/
/*@import "popover";*/
/*@import "carousel";*/
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
.popup-block.add-to-cart .bg-primary.link-to-cart:hover,
button.bg-primary:focus,
.popup-block.add-to-cart .bg-primary.link-to-cart:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
.popup-block.add-to-cart .bg-secondary.link-to-cart:hover,
button.bg-secondary:focus,
.popup-block.add-to-cart .bg-secondary.link-to-cart:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
.popup-block.add-to-cart .bg-success.link-to-cart:hover,
button.bg-success:focus,
.popup-block.add-to-cart .bg-success.link-to-cart:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
.popup-block.add-to-cart .bg-info.link-to-cart:hover,
button.bg-info:focus,
.popup-block.add-to-cart .bg-info.link-to-cart:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
.popup-block.add-to-cart .bg-warning.link-to-cart:hover,
button.bg-warning:focus,
.popup-block.add-to-cart .bg-warning.link-to-cart:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
.popup-block.add-to-cart .bg-danger.link-to-cart:hover,
button.bg-danger:focus,
.popup-block.add-to-cart .bg-danger.link-to-cart:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
.popup-block.add-to-cart .bg-light.link-to-cart:hover,
button.bg-light:focus,
.popup-block.add-to-cart .bg-light.link-to-cart:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
.popup-block.add-to-cart .bg-dark.link-to-cart:hover,
button.bg-dark:focus,
.popup-block.add-to-cart .bg-dark.link-to-cart:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .container::after, .header-bottom .logo::after, .header-bottom .logo a::after, .header-bottom .header-contacts::after, .header-bottom .righ_block::after, .footer-top .contacts .logo::after, .footer-top .contacts .logo a::after, .footer-top .footer-menu::after, .product .short_description::after, .product .characters-table .characters-table__row::after, .kolvo_buy_button_add::after, .product-comments-form::after, .responses_content::after, .catalog-main__wrappa.set-5 > .row::after, .catalog-main::after, .catalog-list::after, .category-list::after, .filter_block_wrapper::after, .filter_block_wrapper .filter_top::after, .filter_block_wrapper .filter_mid::after, .brand-list::after, .search_box .price-wrapper::after, .albums_list::after,
.photos_list::after, .module-inner .news-block::after, .news-list .news-block__item::after, .news-detail .news-detail__text::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*@import "print";*/
