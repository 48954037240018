.podpiska_form {
	.input-text{
		margin: 0 auto;
	}
	.form_block{
		+ div{
			text-align: center;
			padding: 1em 0;
		}
	}
}