.profile-wrapper {
  background: #fff;
  min-width: 260px;
  max-width: 292px;
  padding: 1em;

  .reg_col_1,
  .reg_col_2 {
    display: block;
  }

  .reg_col_1 {
    color: $textColor;
    font-size: 12px;
    padding: 0 0 0.25em;
  }

  .reg_col_2 {
    padding: 0 0 0.25em;
    position: relative;

    &.last {
      padding: 0;
      margin: 1em 0 0;
    }

    .input-text {
      width: 100%;
    }
  }


}



.changepass-block,
.profile-block {

  & > form {
    & > table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

.changepass-block {
  .changepass-block__title {
    margin: 1em 0 0.5em;
    text-align: center;
  }
}


@include media(sm) {

  .profile-block {
    margin: 1em 0 0;


    .profile-table {
      width: 100%;

      tr {
        display: block;

        &:last-child {
          .reg_col_1 {
            line-height: 0;
          }
        }
      }
    }

    .input-text {
      width: 100%;
    }

    .reg_col_1,
    .reg_col_2 {
      display: block;
    }
  }

  .changepass-block {

  }

  .pass-change-table {
    table-layout: fixed;
    width: 100%;

    tr {
      display: block;

      &:last-child {
        .reg_col_1 {
          line-height: 0;
        }
      }
    }

    .input-text {
      width: 100%;
    }

    .reg_col_1,
    .reg_col_2 {
      display: block;
    }
  }

  .reg_col_1 {
    font-size: 14px;
  }

}


.orders_btnm {
  margin: 1em 0 2em;
}

