.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;
  /*background: $white - 50%;*/
  @extend .clearfix;
}


main {
  width: 100%;
  > .container{
    background: $white;
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;
  overflow: hidden;

  @include media(lg) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height:calc(100vh - 99px - 49px - 233px - 48px);

  @include media(lg) {
    float: none;
    width: 100%;
  }
}

section{
  margin: 1em 0 4em;
  padding: 0 1em;
  &:not(#landing-list){
    background: $footerTopBgColor;
    border-bottom: 1px solid $footerTopBgColor - 10%;
  }
  &#landing-list{
    padding: 0;
  }
  &#yamap{
    margin: 1em 0 0;
    padding: 0;
    background: none;
    .module-inner{
      margin: 0;
    }
  }
  .feedback{
    background: $white;
  }
}

.mobile,
.tablet{
  section{
    padding: 0;
  }
}