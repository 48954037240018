/* Базовые: Ширина контента<br><span>($containerWidth)</span> */
$containerWidth: 1200px;
/* Базовые: Подключение Google Fonts<br><span>($fontsGoogle)</span> */
$fontsGoogle: "fonts.googleapis.com/css?family=Ubuntu";
/* Базовые: Толщина шрифта<br><span>($weightFonts)</span> */
$weightFonts: 400,500,700;
/* Базовые: Название шрифта<br><span>($fontName)</span> */
$fontName: "Arial";
/* Базовые: Размер шрифта<br><span>($fontSizeBase)</span> */
$fontSizeBase: 14px;
/* Базовые: Белый цвет<br><span>($white)</span> */
$white: #fff;
/* Базовые: Черный цвет<br><span>($black)</span> */
$black: #222;
/* Базовые: Цвет ссылок<br><span>($linkColor)</span> */
$linkColor: #07295e;
/* Базовые: Цвет ссылок при наведении<br><span>($linkColor--hover)</span> */
$linkColor--hover: #e48a0a;
/* Базовые: Подсветка ссылки<br><span>($webkitHighlightTapColor)</span> */
$webkitHighlightTapColor: rgba(0,0,0, 0.15);
/* Базовые: Цвет текста<br><span>($textColor)</span> */
$textColor: #000;
/* Базовые: Серый цвет<br><span>($grey)</span> */
$grey: #666;
/* Базовые: Цвет иконок<br><span>($iconColor)</span> */
$iconColor: $linkColor;
/* Базовые: Цвет фона<br><span>($bgColor)</span> */
$bgColor: #fff url(../images/fon.jpg) 0 0 fixed;
/* Базовые: Цвет фона после загрузки страницы<br><span>($bgLoaded)</span> */
$bgLoaded: url(../images/bg.jpg) 0 0;
/* Базовые: Цвет фона верхней шапки<br><span>($headerTopBgColor)</span> */
$headerTopBgColor: ;
/* Базовые: Цвет фона нижней шапки<br><span>($headerBottomBgColor)</span> */
$headerBottomBgColor: #fff;
/* Базовые: Цвета ошибок<br><span>($colorError)</span> */
$colorError: rgba(253, 30, 15, 0.7);
/* Базовые: Цвета ОК<br><span>($colorOk)</span> */
$colorOk: rgba(169, 253, 0, 0.7);
/* Базовые: Цвет фона верхней шапки на мобильных<br><span>($headerTopBgColorMobile)</span> */
$headerTopBgColorMobile: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
/* Базовые: Высота мобильной фиксированной шапки<br><span>($mobileFixedHeaderHeight)</span> */
$mobileFixedHeaderHeight: 44px;
/* Header: Logo раположение<br><span>($logoPos)</span> */
$logoPos: left;
/* Header: Logo margin<br><span>($logoMargin)</span> */
$logoMargin: 0;
/* Header: Logo padding<br><span>($logoPadding)</span> */
$logoPadding: 0 1em;
/* Header: Logo ширина<br><span>($logoWidth)</span> */
$logoWidth: 15%;
/* Header: Logo img ширина<br><span>($logoWidth)</span> */
$logoImgwidth: 100%;
/* Header: Logo img макс ширина<br><span>($logoImgmaxWidth)</span> */
$logoImgmaxWidth: 116px;
/* Header: Logo img высота<br><span>($logoImgheight)</span> */
$logoImgheight: 100%;
/* Header: Logo img макс высота<br><span>($logoWidth)</span> */
$logoImgmaxHeight: 108px;
/* Header: Header-Contacts раположение<br><span>($heaContFloat)</span> */
$heaContFloat: right;
/* Header: Header-Contacts padding<br><span>($heaContPadding)</span> */
$heaContPadding: 0.5em 0 0;
/* Header: Header-Contacts ширина<br><span>($heaContWidth)</span> */
$heaContWidth: 85%;
/* Footer: Footer-Top background<br><span>($footerTopBgColor)</span> */
$footerTopBgColor: url(../images/footer-fon.jpg) 0 0;
/* Footer: Footer-Bottom background<br><span>($footerBottomBgColor)</span> */
$footerBottomBgColor: $footerTopBgColor;
/* Рамки: Цвет<br><span>($borderColor)</span> */
$borderColor: #3d557b;
/* Рамки: Скругление<br><span>($borderRadius)</span> */
$borderRadius: 5px;
/* Рамки: Стиль<br><span>($border)</span> */
$border: 1px solid $borderColor;
/* Кнопки: Градиент да/нет? Если нет, используется цвет 1<br><span>($isButtonGradient)</span> */
$isButtonGradient: true;
/* Кнопки: Тень, да/нет<br><span>($isButtonShadow)</span> */
$isButtonShadow: false;
/* Кнопки: Рамка, да/нет<br><span>($isButtonBorder)</span> */
$isButtonBorder: true;
/* Кнопки: Цвет фона 1 (нижний)<br><span>($buttonBgColor1)</span> */
$buttonBgColor1: #ee6300;
/* Кнопки: Цвет фона 1 (верхний)<br><span>($buttonBgColor2)</span> */
$buttonBgColor2: #dfce42;
/* Кнопки: Тень<br><span>($buttonShadow)</span> */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;
/* Кнопки: Цвет текста<br><span>($buttonColor)</span> */
$buttonColor: #002949;
/* Кнопки: Скругления<br><span>($buttonBorderRadius)</span> */
$buttonBorderRadius: $borderRadius;
/* Кнопки: Цвет фона 2 (нижний) при наведении<br><span>($buttonBgColor1--hover)</span> */
$buttonBgColor1--hover: #ff8513;
/* Кнопки: Цвет фона 2 (верхний) при наведении<br><span>($buttonBgColor2--hover)</span> */
$buttonBgColor2--hover: #fff064;
/* Кнопки: Цвет текста при наведении<br><span>($buttonColor--hover)</span> */
$buttonColor--hover: #002949;
/* Кнопки: Рамка<br><span>($buttonBorder)</span> */
$buttonBorder: 1px solid #ce5500;
/* Кнопки: Цвет рамки при наведении<br><span>($buttonBorderColor--hover)</span> */
$buttonBorderColor--hover: #f07711;
/* Кнопки: Тень наведении<br><span>($buttonShadow--hover)</span> */
$buttonShadow--hover: none;
/* Кнопки: Цвет фона 1 (нижний) активный<br><span>($buttonBgColor1--active)</span> */
$buttonBgColor1--active: ;
/* Кнопки: Цвет фона 2 (верхний) активный<br><span>($buttonBgColor2--active)</span> */
$buttonBgColor2--active: ;
/* Кнопки: Цвет текста активный<br><span>($buttonColor--active)</span> */
$buttonColor--active: ;
/* Кнопки: Цвет рамки активный<br><span>($buttonBorderColor--active)</span> */
$buttonBorderColor--active: ;
/* Кнопки: Тень в активном состоянии<br><span>($buttonShadow--active)</span> */
$buttonShadow--active: none;
/* Инпуты: Есть ли рамка<br><span>($isInputBorder)</span> */
$isInputBorder: true;
/* Инпуты: Есть ли тень<br><span>($isInputShadow)</span> */
$isInputShadow: true;
/* Инпуты: Цвет текста<br><span>($inputColor)</span> */
$inputColor: $textColor;
/* Инпуты: Цвет фона<br><span>($inputBg)</span> */
$inputBg: #fff;
/* Инпуты: Цвет рамки<br><span>($inputBorderColor)</span> */
$inputBorderColor: $borderColor;
/* Инпуты: Тень<br><span>($inputShadow)</span> */
$inputShadow: inset 0 2px 7px 0 #a6a6a7;
/* Модули: Цвет фона<br><span>($moduleBg)</span> */
$moduleBg: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
/* Модули: Цвет фона заголовков<br><span>($moduleHeaderBg)</span> */
$moduleHeaderBg: linear-gradient(to bottom,#3c557b,#213b63);
/* Модули: Цвет заголовков<br><span>($moduleHeaderColor)</span> */
$moduleHeaderColor: #fff;
/* Модули: Рамка<br><span>($moduleBorder)</span> */
$moduleBorder: 0;
/* Модули: Тень сверху<br><span>($module_top__box-shadow)</span> */
$module_top__box-shadow: inset 0 1px 0px 0 #fff;
/* Модули: Цвет фона заголовков в мобильной версиии<br><span>($moduleMobileHeaderBg)</span> */
$moduleMobileHeaderBg: ;
/* Модули: Цвет заголовков в мобильной версиии<br><span>($moduleMobileHeaderColor)</span> */
$moduleMobileHeaderColor: $white;
/* Горизонтальное меню: Градиент да/нет Если нет, используется цвет 1<br><span>($isTopMenuGradient)</span> */
$isTopMenuGradient: false;
/* Горизонтальное меню: Тень да/нет<br><span>($isTopMenuShadow)</span> */
$isTopMenuShadow: false;
/* Горизонтальное меню: Рамка да/нет<br><span>($isTopMenuBorder)</span> */
$isTopMenuBorder: true;
/* Горизонтальное меню: Закругления да/нет<br><span>($isTopMenuBorderRounded)</span> */
$isTopMenuBorderRounded: false;
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1)</span> */
$topMenuBgColor1: linear-gradient(180deg, #fff 2%, #dde1e8 3%, #dadee5 100%);
/* Горизонтальное меню: Цвет фона 2 (верхний)<br><span>($topMenuBgColor2)</span> */
$topMenuBgColor2: ;
/* Горизонтальное меню: Рамка<br><span>($topMenuBorder)</span> */
$topMenuBorder: 1px solid $linkColor;
/* Горизонтальное меню: Тень кнопок<br><span>($topMenuShadow)</span> */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;
/* Горизонтальное меню: Цвет текста<br><span>($topMenuColor)</span> */
$topMenuColor: ;
/* Горизонтальное меню: Скругления<br><span>($topMenuBorderRadius)</span> */
$topMenuBorderRadius: ;
/* Горизонтальное меню: Цвет текста при наведении<br><span>($topMenuColor--hover)</span> */
$topMenuColor--hover: ;
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1--selected)</span> */
$topMenuBgColor1--selected: ;
/* Горизонтальное меню: Цвет фона 2 (верхний) выбранный раздел<br><span>($topMenuBgColor2--selected)</span> */
$topMenuBgColor2--selected: ;
/* Товар: Цвет заголовка<br><span>($prodTitleColor)</span> */
$prodTitleColor: $linkColor;
/* Товар: Цвет текста<br><span>($prodTextColor)</span> */
$prodTextColor: $textColor;
/* Товар: Цвет текста следующий<br><span>($prodSecondColor)</span> */
$prodSecondColor: #8f8f8f;
/* Товар: Цвет текущей цены<br><span>($prodCurrentPriceColor)</span> */
$prodCurrentPriceColor: $black;
/* Товар: Цвет старой цены<br><span>($prodOldPriceColor)</span> */
$prodOldPriceColor: #a4a4a4;