.news-block__title {
	& > a {
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}

.news-block__text {
	font-size: 14px;
	margin: 0.5em 0;
	line-height: 1.333;
	text-align: justify;
}

.news-block__date {
	vertical-align: middle;
	margin: 0 0 0.5em;

	& > span {
		background: $linkColor;
		color: #fff;
		font-size: 13px;
		padding: 0 8px 1px;
		white-space: nowrap;
		border-radius: $borderRadius;

		&.news-publish {
			background: none;
			display: none;
			color: $textColor;
			font-size: 10px;
			padding: 0;
		}
	}
}

/* Новости на главной     */
/* ...в левой колонке     */
.news-block {

	.news-block__item {
		margin: 0 0 1em;
		padding: 0 0 1em;
		border-bottom: 1px solid $linkColor;
	}


	.news-block__header {
		display: table;
		width: 100%;
	}


	.news-block__date,
	.news-block__title {
	}


	.news-block__date {
	}


	.news-block__image {
		@extend .img-responsive;
		border-radius: $borderRadius;
	}


	.readmore-link {
		display: block;
	}

	.news-all,
	.news-archive {
		padding: 0 0 0.5em;
		text-align: center;

		& > a {
			text-transform: uppercase;
		}
	}

	@include media(xmd) {
		.news {
			width: calc(50% - 1em);

			&:last-child {
				width: 100%;
			}
		}
	}

	@include media(sm) {
		.news {
			width: 100%;
		}
	}
}



/* Новости на главной     */
/* ... в контент области  */
.module-inner {
	.news-block {
		@extend .clearfix;
		.news-block__item {
			float: left;
			margin: 0 2em 1em 0;
			width: calc(33.333% - 1.34em);

			&:nth-child(3) {
				margin: 0 0 1em;
			}
			@include media(md){
				width: 100%;
			}
		}
	}
}


/* Список новостей - news/page/1 */
.news-list {

	.readmore-link {
		display: block;
		margin: 1em 0 0;
	}

	.news-block__image {
		float: left;
		margin: 0 1em 0.5em 0;
		max-width: 200px;
		border-radius: $borderRadius;
	}

	.news-block__item {
		@extend .clearfix;

		border-bottom: 1px solid $borderColor;
		margin: 0 0 2em;
		padding: 0 0 1em;

		&:last-child {
			border-bottom: none;
		}
	}

	.news-block__header {
		display: table;
		width: 100%;

		.news-block__date{
			text-align: right;
		}
		.news-block__date,
		.news-block__title {
			display: table-cell;
		}
	}
}

section{
	.news-block{
		.news-block__item{
			padding: 1em;
			background: $white;
			border: 1px solid $footerTopBgColor - 10%;
		}
	}
}
