/**************************************/
/*    Миксины для медиа запросов      */
/*    Использование:                  */
/*    @include media(lg/md/.../) {    */
/*         селектор {}                */
/*    }                               */
/*                                    */
/**************************************/

/**************************************/
/*    Брейкпоинты                     */
/**************************************/

$xs: 414px;
$sm: 480px;
$xmd: 600px;
$md: 768px;
$slg: 860px;
$lg: 992px;
$xlg: 1200px;

/* Первые десктопы */
@mixin media($media) {

    @if $media == xs {
        @media only screen and (max-width: $xs) {
            @content;
        }
    }

    @else if $media == xs-port {
        @media only screen and (max-width: $xs) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == xs-land {
        @media only screen and (max-width: $xs) and (orientation: landscape) {
            @content;
        }
    }


    @else if $media == sm {
        @media only screen and (max-width: $sm) {
            @content;
        }
    }

    @else if $media == sm-port {
        @media only screen and (max-width: $sm) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == sm-land {
        @media only screen and (max-width: $sm) and (orientation: landscape) {
            @content;
        }
    }


    @else if $media == xmd {
        @media only screen and (max-width: $xmd) {
            @content;
        }
    }

    @else if $media == xmd-port {
        @media only screen and (max-width: $xmd) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == xmd-land {
        @media only screen and (max-width: $xmd) and (orientation: landscape) {
            @content;
        }
    }


    @else if $media == md {
        @media only screen and (max-width: $md) {
            @content;
        }
    }

    @else if $media == md-port {
        @media only screen and (max-width: $md) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == md-land {
        @media only screen and (max-width: $md) and (orientation: landscape) {
            @content;
        }
    }

    @else if $media == slg {
        @media only screen and (max-width: $slg) {
            @content;
        }
    }

    @else if $media == lg {
        @media only screen and (max-width: $lg) {
            @content;
        }
    }
    @else if $media == lg-port {
        @media only screen and (max-width: $lg) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == lg-land {
        @media only screen and (max-width: $lg) and (orientation: landscape) {
            @content;
        }
    }

    @else if $media == xlg {
        @media only screen and (max-width: $xlg) {
            @content;
        }
    }
    @else if $media == xlg-port {
        @media only screen and (max-width: $xlg) and (orientation: portrait) {
            @content;
        }
    }

    @else if $media == xlg-land {
        @media only screen and (max-width: $xlg) and (orientation: landscape) {
            @content;
        }
    }

    @else {
        @media only screen and (max-width: $media) {
            @content;
        }
    }
}
