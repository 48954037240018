
$setNumber: 0;


@mixin icon($row, $col, $hover: 0) {

  $hoverOffset: 0;

  @if $hover > 0 {
    $hoverOffset: 22px;
  }

  @if $setNumber != 0 {
    $col: $setNumber;
  }


  background: url('/assets/images/sprite.svg') (45px - (45px * $col)) ((45px - (45px * $row)) - $hoverOffset) no-repeat;

  content: '';
  @content;
}


@mixin spriteIcon($row, $col) {

  &:before {
    background: url('/assets/images/sprite.svg') $row $col no-repeat;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;

    @content;

    z-index: 2;
  }
}