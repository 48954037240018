.search-form {
	position: relative;
	margin: 7px 0;
	width: 100%;
	@include media(lg) {
		max-width: 100%;
	}

	.input-text {
		width: 100%;
		padding: 5px 2.5em 5px 0.5em;
	}
}

.search-form__button {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 28px;
	background: $borderColor;
	border-radius: 3px;
	margin: 2px;
	-webkit-tap-highlight-color: $webkitHighlightTapColor;

	@include fontawesome('\f002') {
		font-size: 16px;
		margin-left: -7px;
		margin-top: -13px;
	}

	&:not(.disabled) {
		background: linear-gradient(to bottom,#f0df53,#ff7402);

		&:hover {
		}
	}

	&.disabled {
		cursor: default;
		&:before {
			color: $white;
		}
	}
}


.search-close__button {
	display: none;
}


.mobile {
	.search-close__button {
		display: block;
		height: 44px;
		width: 44px;
		position: absolute;
		top: 0;
		left: 0;

		&:before {
			content: '\f00d';
			font: normal 21px/1 "FontAwesome";
			color: $black;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -11px 0 0 -8px;
		}
	}
}


.search_box {
	border-bottom: 1px solid $borderColor;
	padding: 1em 0;
	margin-bottom: 1em;

	& > h3 {
		& > a {
			color: $linkColor;
			font-size: 21px;
			font-weight: normal;
			text-transform: uppercase;
			text-decoration: none;

			&:hover {
				color: $linkColor--hover;
			}
		}
	}

	.search-photo {

		float:left;
		margin: 0 1em 0.5em 0;

		& > a {
			display: block;

			& > img {
				max-width: 220px;
			}
		}
	}

	.price-wrapper {
		@extend .clearfix;

		margin: 1em 0 0;
	}

	.kolvo_buy_button_add {
		float: left;
		margin: 0;
	}

	.search-price {
		float: left;
		margin-right: 10px;

		.price-title {
			font-size: 15px;
			font-weight: bold;
		}

		.price {
			font-size: 24px;
			font-weight: bold;
		}

		.currency {
			font-size: 16px;
			font-weight: bold;
		}
	}
}


.main_src_text {
	min-height: 95px;
}

.search_box .buy_link {
	float: none;
	display: inline-block;
}

.content_search img {
	margin: 0 10px 10px 0;
}

.content_search h3 {
	margin: 0;
	font-size: 14px;
}

.content_search  div.search_box {
	margin: 0 0 15px;
}

.content_search cite {
	margin: 2px 0;
	font-style: normal;
	color: #ca0d1f;
}

.content_search p {
	margin: 0;
}

.search_result {
	position:absolute;
	top: 30px;
	left: 0;
	margin: 4px 0 0;
	background: #fff;
	border-radius: 5px;
	width: 300px;
	font-size: 11px;
	line-height: 16px;
	z-index: 999;
	text-align: left;
}

.search_result > div {
	box-shadow: 1px 1px 5px #666;
	border: 1px solid #C6C6C6;
}

.search_result > div > .search_box {
	padding:5px 5px;
	border-top: 1px solid #C6C6C6;
	cursor:pointer;
}

.search_result > div > .search_box p {
	margin: 0;
}

.search_result > div > .search_box:hover {
	background:#eee;
}
.search_result > div > .search_box:first-child {
	padding:2px 5px;
	border-top: none;
}

.search_result > div > .search_box img {
	margin: 0 10px 0;
}

span.search_sell {
	color:#000;
	background:#ffff00;
}

.ui-autocomplete-category {
	font-weight: bold;
	padding: 0.2em 0.4em;
	margin: 0.8em 0 0.2em;
	line-height: 1.5;
	font-size: 16px;
}

.ui-autocomplete-category:first-child {
	padding: 0 0.4em !important;
	margin: 0 0 0.2em !important;
}

.ui-state-focus {
	background: #eee !important;
	border: none !important;
}

.ui-autocomplete-category {
	border-bottom: 1px solid #ccc;
}

.ui-menu-item {
	margin: 0 !important;
	padding: .4em 1em !important;
	width: auto !important;
}

.ui-autocomplete {
	z-index: 999 !important;
}

.top-menu_search{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
}


.img_search .search_form > .search_input {
	padding-right: 45px;
}

.img_search .search_form > .search_button {
	width: 45px;
	height: 55px;
	background: url('../images/img_search.png') 50% -6px no-repeat;
	color: #fff;
}
