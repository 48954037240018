/* Раскладки каталога */

.catalog-main__item {
  &.set-2 {
    float: left;
    margin: 0 14px 14px 0;
    width: calc(50% - 7px);

    &:nth-child(2) {
      margin: 0 0 14px;
    }
  }
}


/* 3 */
.catalog-main__wrappa {
  &.set-3 {
    float: left;
    margin: 0 14px 14px 0;
    width: calc(50% - 7px);

    &:nth-child(2) {
      margin: 0 0 14px;
    }
    &.col-bs-2 {
      padding: 0;

      & > .catalog-main__item {

        &:first-child {
          margin: 0 0 14px;
        }
      }
    }
  }
}



/* 4 */
.catalog-main__wrappa {
  &.set-4 {
    float: left;
    margin: 0 14px 14px 0;
    width: calc(30% - 9px);


    &.col-1 {
      padding: 0;

      .catalog-main__title {
        top: 44%;
      }
    }
    &.col-2 {
      padding: 0;
      width: calc(40% - 9px);

      .catalog-main__title {
        top: 44%;

        & > h2 {
          font-size: 26px;
        }
      }


      & > .catalog-main__item {

        &:first-child {
          margin: 0 0 14px;
        }
      }
    }
  }


}

.catalog-main__item {
  &.set-4 {
    float: left;
    margin: 0 0 1em;
    width: calc(30% - 10px);
  }
}




/* 5 */
.catalog-main__wrappa {
  &.set-5 {
    float: left;
    margin: 0 14px 14px 0;
    width: calc(50% - 7px);

    &.col-1 {
      .catalog-main__item {
        &:first-child {
          margin: 0 0 14px;
        }
      }
    }

    &.col-3 {
      padding: 0;
      margin: 0 0 14px;

      & > .catalog-main__item {
        margin: 0 0 14px;
      }
    }

    & > .row {
      @extend .clearfix;

      .catalog-main__item {
        margin: 0 14px 14px 0;
        width: calc(50% - 7px);

        &.set-5 {
          margin: 0 0 14px;
        }
      }
    }
  }
}


/* 6 */
.catalog-main__wrappa {
  &.set-6 {

  }
}





/* 7 */
.catalog-main__wrappa {
  &.set-7 {

  }
}




/* 8 */
.catalog-main__wrappa {
  &.set-8 {

  }
}

/* Конец раскладки каталога */




/* Временно */
.catalog-main {

  .catalog-main__item.set-6-more {
    float: left;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em);

    &:nth-child(3n + 3) {
      margin: 0 0 2em;
    }
  }
}

.catalog-main{
  .catalog-main__wrappa,
  .catalog-main__item{
    &.set-1,
    &.set-2,
    &.set-3,
    &.set-4,
    &.set-5,
    &.set-6,
    &.set-6-more,
    &.set-7,
    &.set-8{
      @include media(xmd){
        width: 100%;
      }
      .row{
        .catalog-main__item{
          @include media(slg){
            width: 100%;
          }
          .catalog-main__title{
          }
        }
      }
      h2{
        @include media(slg){
          font-size: $fontSizeBase;
          line-height: inherit;
        }
      }
      .catalog-main__title{
        @include media(slg){
          top: 0;
          margin: 1em;
        }
      }
      .catalog-main__image{
        @include media(slg){
          height: 100px;
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
}

/* */


.catalog-main__item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: $borderRadius;
  z-index: 1;

  &:hover {

    & > .back {
      background: rgba(0, 0, 0, 0.8);
      transition: background 0.3s ease;
    }

    .catalog-main__image {
      transform: scale(1.1);
      transition: transform 0.3s ease-out;
    }

    .catalog-main__title {
      & > h2 {
        transition: color 0.3s ease;
      }
      & > p {
        transition: color 0.3s ease;
      }
    }
  }

  & > a {
    display: block;
  }

  & > .back {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}


.catalog-main__image {
  display: block;
  height: auto;
  max-width: 100%;
  transform-origin: center;
  transform: scale(1);
  width: 100%;
  will-change: transform;
}

.catalog-main__title {
  position: absolute;
  top: 50%;
  left: 2em;
  margin: -50px 0 0 0;
  pointer-events: none;
  z-index: 5;

  & > h2 {
    color: $white;
    font-weight: bold;
    line-height: 1;
    padding: 0 0 10px;
    text-transform: uppercase;
  }

  & > p {
    color: $white - 20%;
    font-size: 12px;
    line-height: 1.2;
  }
}



/* Стили главного каталога */
.catalog-main {
  @extend .clearfix;
  margin: 2em 0 0;
}

