@import "../system/icon";

header{
	background: $headerTopBgColor;
	@extend .animated;
	.container{
		background: $white;
	}
	&.scrolled{
		@extend .animated;
		@extend .fadeInDown;
		position: fixed;
		z-index: 100;
		width: 100%;
		top: 0;
		padding: 0;
		.wow{
			visibility: visible !important;
		}
		.header-bottom{
			.header-contacts{
				padding: 0.8em 1em 0;
			}
			.logo{
				padding: 0.3em 1em;
				.logo-img{
					max-width: 80px;
					max-height: 75px;
				}
				.slogan{
					font-size: 16px;
					padding: 0.4em 0 0 1em;
				}
			}
			.phones{
				.icon {
					margin: -15px 0 0;
				}
			}
			.phones-title{
				display: none;
			}
			.phones-list__item{
				> a{
					line-height: 30px;
				}
			}
			.mail{
				line-height: 27px;
				.mail-title{
					display: none;
				}
				.mail-wrapper {
					margin: 0;
				}
			}
			.times-block{
				.title{
					display: none;
				}
			}
			.righ_block{
				.search{
					display: none;
				}
			}
			.header-cart{
				.cart{
					> .header-cart__title{
						display: none;
					}
					&:before{
						font-size: 25px;
					}
				}
			}
			.call-order{
				&.button{
					padding: 0.5em 14px 0;
				}
			}
		}
		nav{
			.menu_level_1{
				> li{
					> a{
						padding: 0.5em;
					}
				}
			}
		}
	}
}

.header-bottom {
	.logo {
		float: $logoPos;
		width: $logoWidth;
		margin: $logoMargin;
		padding: $logoPadding;
		text-align: center;
		@extend .clearfix;
		@include media(slg){
			width: 100%;
		}
		a{
			display: block;
			@extend .clearfix;
		}
		.logo-img{
			width: $logoImgwidth;
			max-width: $logoImgmaxWidth;
			height: $logoImgheight;
			max-height: $logoImgmaxHeight;
			vertical-align: middle;
		}
		.slogan{
			font-size: 20px;
			font-weight: 900;
			float: left;
			color: $textColor;
			text-align: center;
			padding: 0.8em 0 0 1em;
			text-transform: uppercase;
		}
	}
	.header-contacts {
		float: $heaContFloat;
		padding: $heaContPadding;
		width: $heaContWidth;
		@extend .clearfix;
		@include media(slg){
			width: 100%;
		}
	}
	.phones {
		float: left;
		padding: 0 1em 0 2em;
		width: 33%;
		@include media(slg){
			width: 100%;
		}
		@include media(slg){
			padding: 0 1em;
		}
	}
	.mail {
		float: left;
		padding: 0 1em;
		width: 22%;
		@include media(slg){
			width: 100%;
		}
		.mail-wrapper {
			padding: 0 0 0 2em;
		}
	}
	.times-block {
	}
	.righ_block {
		float: right;
		padding: 0 1em;
		width: 45%;
		@extend .clearfix;
		@include media(slg){
			width: 100%;
		}
	}
	.call-order-wrapper{
		width: 50%;
		float: left;
		@include media(slg){
			width: 100%;
			text-align: center;
		}
	}
	.search{
		float: right;
		width: 90%;
		@include media(slg){
			float: left;
			width: 100%;
		}
	}
	.auth-block {
		.auth-box {
			float: left;
			padding: 10px 1em 0;
			.reg {
				margin-right: 10px;
			}
			.enter {
			}
		}
	}
}
/* конец раскладки */
.header-bottom {
	& > .container {
		position: relative;
	}
	.phones {
		position: relative;
		.icon {
			margin: -8px 0 0;
			@include fontawesome('\f095') {
				color: $iconColor;
			}
			@include media(slg){
				margin: -8px 0 0 15px;
			}
		}
	}
	.phones-list {
		display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
		@include media(slg){
			padding: 0 0 0 1.5em;
		}
	}
	.phones-list__item {
		& > a {
			color: $textColor;
			font-size: 16px;
			font-weight: bold;
			line-height: 36px;
			text-decoration: none;
			white-space: nowrap;
			display: inline-block;
			vertical-align: middle;
			@include media(slg){
				line-height: normal;
			}
		}
		> span{
			display: inline-block;
			vertical-align: middle;
			color: $linkColor;
			padding: 0 0 2px 5px;
			@include media(xlg){
				display: none;
			}
			@include media(slg){
				display: inline-block;
			}
		}
		.pref {
			font-weight: normal;
		}
	}
	.mail {
		.mail-wrapper {
			position: relative;
			margin: 0 0 1em;
			.icon {
				@include fontawesome('\f0e0') {
					color: $iconColor;
					margin: -13px 0 0 -10px;
				}
			}
			@include media(slg) {
				margin: 0;
			}
		}
	}
	.mail-link {
		font-size: 15px;
		vertical-align: middle;
		white-space: nowrap;
	}
	.phones-title,
	.times-block .times-title,
	.mail-title{
		font-weight: 600;
	}
	.call-order {
		&.button{
			background: none;
			color: $linkColor--hover;
			text-align: center;
			font-weight: bold;
			text-decoration: underline;
			border: none;
			&:hover{
				color: $linkColor;
			}
			&:active {
				box-shadow: none;
			}
		}
	}
	.times-block {
		.title {
			font-size: 15px;
			margin: 0 0 3px;
			font-weight: 600;
			@include media(md) {
				padding: 0 0 0 2em;
			}
		}
	}
	.times {
		padding: 0 0 0 1.5em;
		position: relative;
		white-space: nowrap;
		@include media(md) {
			padding: 0 0 0 2em;
		}
		& > span {
			font-weight: 600;
		}
		@include fontawesome('\f017') {
			color: $iconColor;
			left: 0;
			margin: -12px 0 0 0;
			@include media(md) {
				margin: -25px 0 0;
			}
		}
	}
}
.header-cart {
	position: relative;
	float: right;
	width: 50%;
	cursor: default;
	text-align: right;
	user-select: none;
	@include media(slg){
		width: 100%;
	}
	&.open {
		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}
	.cart {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		padding: 3px 0;
		border-radius: 0 5px 5px 0;

		& > .header-cart__title-mob {
			display: none;
		}
		& > .header-cart__title {
			display: none;
		}
		.summ-block {
			position: relative;
			padding: 0 0 0 60px;
			.header_cart-goods-text{
				font-size: 12px;
			}
		}
		& > .count {
			position: relative;
			padding: 0 0 0 60px;
			& > .count-text {
				font-size: 12px;
				display: inline-block;
			}
			& > .count-quantity {
				display: inline-block;
			}
		}
	}
}
.desktop {
	.header-cart {
		border-radius: 5px;
		border: 1px solid #df6600;
		box-shadow: 1px 1px 3px -1px #000;
		background: linear-gradient(to bottom,#f0df53,#ff7402);
		.cart {
			&:before {
				content: '';
				position: absolute;
				left: 50px;
				top: 0px;
				background: $white;
				width: 100%;
				height: 100%;
				z-index: 0;
			}
			&:after {
				content: '.';
				font-size: 0px;
				line-height: 0px;
				display: block;
				width: 33px;
				height: 31px;
				position: absolute;
				top: 8px;
				left: 7px;
				background: url(../images/sprite2.png) -65px -107px no-repeat;
				z-index: 2;
			}
			&.full {
				cursor: pointer;
			}
			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}
}

#progressBar{
	height: 3px;
	background: $linkColor;
	top: 0;
	left: 0;
	right: auto;
	bottom: auto;
	position: fixed;
	z-index: 9999;
	pointer-events: none;
}
