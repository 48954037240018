nav {
	width: 100%;
	position: relative;
	>.container{
		@include navtop();
	}

	.menu_level_1 {
		display: table;
		list-style: none;
		width: 100%;
		@include media(md) {
			display: block;
			text-align: center;
		}

		& > li {
			display: table-cell;
			position: relative;
			vertical-align: top;
			padding: 0 1em;
			&:first-child{
				padding: 0 1em 0 0;
			}
			&:last-child{
				padding: 0 0 0 1em;
			}
			@include media(md) {
				display: inline-block;
			}

			& > a {
				color: $linkColor;
				font-weight: 600;
				text-shadow: 0 2px 1px #fff;
				display: block;
				padding: 0.7em 0.5em;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				border-left: 1px solid transparent;
				border-right: 1px solid transparent;
				@include media(md) {
					padding: 0.5em;
				}
			}

			&.selected {
				cursor: default;
				& > a {
					background: linear-gradient(to bottom, #f0df53, #ff7402);
					border-color: #a15500;
				}
			}
			+ li{
				&:before {
					content: '';
					position: absolute;
					background: $linkColor;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					top: 50%;
					margin: -3px 0 0 -3px;
					left: 0;
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {

						&:hover {
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.selected {
						& > a {

							&:hover {
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: none;
		display: table;
		list-style: none;
		margin: 0 0 0 1em;
		min-width: 220px;
		width: 100%;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		border-radius: 0 0 $borderRadius $borderRadius;

		& > li {
			display: table-cell;
			padding: 0.5em 1em;

			.menu-item__photo {
				float: left;
				width: 50%;

				& > img {
					display: block;
					height: auto;
					max-width: 100px;
					width: 100%;
				}
			}

			.menu-item__description {
				float: right;
				width: 50%;

				& > span {
					font-size: 12px;
				}
			}

			.menu-item__header {

				& > span {
					color: $textColor + 50%;
					font-size: 12px;
					vertical-align: top;
				}
			}

			& > a {
				color: $textColor;
				display: block;
			}
			ul {
				display: block;
				list-style: none;
				padding: 0.5em;

				& > li {
					& > a {
						color: $textColor;
						display: block;
						white-space: nowrap;
						&:hover{
							color: $linkColor--hover;
						}
					}

					&.selected {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}
}

.desktop {

	nav {
		.menu_level_1 {
			position: relative;
			width: 100%;

			& > li {

				/* Показываем всплывашку на ховер */
				&.subcat {
					&:hover {
						.menu_level_2 {
							opacity: 1;
							transition: opacity 0.3s ease, transform 0.3s ease-out;
							visibility: visible;
							transform: translate3d(0, 0, 0);
							box-shadow: $module_top__box-shadow;
							border: 1px solid #07295e;
						}
					}
				}

				&:not(.selected) {
					&:hover {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}


		.menu_level_2 {
			display: table-row;
			left: 0;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				float: left;
				width: 100%;

				&.selected {
					& > a {
						color: $linkColor--hover;
					}
				}

				&:not(.selected) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3 {
			& > li {
				&:not(.selected) {
					&:hover {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.search {
			float: right;
			margin: 3px 0 0;
			padding: 0 1em;
			width: 250px;
			@include media(lg) {
				margin: 0;
				width: 100%;
			}
		}

		.menu-wrapper {
			float: left;
			width: 100%;
		}
	}
}

