.module {
	@include module(false);
	.filter-wrapper,
	.vote-block{
		padding: 1em;
	}
	.news-block{
		padding: 1em 1em 0;
	}
}
/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	margin: 1em 0 2em;
	& > .module-header {
		background: none;
		padding: .3em 0 0 .5em;
		margin: 0 0 1.5em;
		text-align: right;
		border-bottom: 1px solid #3c557b;
		& > span,
		& > h2 {
			display: inline-block;
			color: $white;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			padding: .3em .5em;
			background: $moduleHeaderBg;
			box-shadow: $module_top__box-shadow;
			border: 1px solid #3c557b;
			border-radius: $borderRadius $borderRadius 0 0;
			min-width: 242px;
			text-align: center;
		}
	}
}
.module-wrapper {
	position: relative;
}
/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor--hover;
		}
	}

	.brands-list__link {
		color: $linkColor;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor--hover;
		}
	}
}
.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}
.mobile,
.tablet {
	section{
		.module-inner {
			padding: 1em 0;
		}
	}
	.module,
	.module-inner {

		& > .module-header {
			background: $moduleMobileHeaderBg;

			&.toggle{
				position: relative;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $white;
					left: inherit;
					right: 1em;
					margin: -10px 0 0;
				}
				&.active{
					&:before{
						content: '\f077';
					}
				}
			}

			& > span,
			& > h2 {
				color: $moduleMobileHeaderColor;
				& > a {
					text-decoration: none;
					color: $moduleMobileHeaderColor;
				}

			}
		}

		.module-wrapper {
			padding: 0;
			margin: 0 0 1em;
		}
		.module-header{
			text-align: center;
		}
	}
	.main-left-menu{
		margin: 0 0 1em;
		.module-header{
			margin: 0;
		}
		.module-wrapper {
			@include media(md) {
				display: none;
			}
		}
	}
}

